<template>
  <div
    class="flex flex-col h-screen justify-between bg-cover bg-center bg-[url(../assets/monarch_logo_mini.png)] lg:bg-[url(../assets/home_page.jpg)]"
  >
    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <LoginWarningsModal
      v-if="showLoginWarningsModal"
      @close="handleCloseLoginWarningsModal($event)"
      :employee="employee"
    >
    </LoginWarningsModal>

    <header class="text-xl">
      <div v-if="errorMessage">
        <div class="flex justify-end">
          <div class="btn-icon" @click="handleResetErrorMessage">
            <i class="fa-solid fa-xmark"></i>
          </div>
        </div>
        <p class="bg-red-500 text-white p-3 rounded-md">
          {{ errorMessage }}
        </p>
      </div>
      <div v-else>
        <div v-if="user">
          <div class="flex justify-between mb-3">
            <div class="giant-logo"></div>
            <div class="monarch-logo"></div>
          </div>

          <div class="flex">
            <div>{{ `Logged in as:` }}</div>
            <div class="font-bold ml-2">
              {{ `${user.surname}, ${user.givenName}` }}
            </div>
          </div>

          <div>{{ `${user.securityGroup.name}` }}</div>

          <div class="flex justify-between mt-3">
            <div
              class="text-3xl text-yellow-500 tracking-widest"
              v-if="environment !== 'PROD'"
            >
              {{ `${environment} - FOR TESTING ONLY` }}
            </div>
            <div v-else></div>

            <button
              v-if="user"
              @click="handleLogout()"
              type="button"
              class="rounded-md bg-red-600 px-2.5 py-1.5 text-sm text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            >
              Logout
            </button>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-between mb-3">
            <div class="giant-logo"></div>
            <div class="monarch-logo"></div>
          </div>

          <div class="flex justify-between mt-3">
            <div
              @click="getLoginHelp"
              class="text-base text-blue-700 cursor-pointer"
            >
              Login Help
            </div>
            <button
              v-if="!user"
              @click="handleLogin"
              type="button"
              class="ml-1 rounded-md bg-green-600 px-2.5 py-1.5 text-sm text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </header>

    <div class="content">
      <div class="flex flex-wrap">
        <!-- Flight Board -->

        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="enableFlightBoard"
          @click="handleNavigateToFlightBoard"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-plane text-blue-500"
            ></i>
            <span>Flight Board</span>
          </div>
        </div>

        <!-- Assignment Board -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="enableAssignmentBoard"
          @click="handleNavigateToAssignmentBoard"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-person-walking-luggage text-orange-600"
            ></i>
            <span>Assignments</span>
          </div>
        </div>

        <!-- Notifications -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="enableNotifications"
          @click="handleNavigateToNotifications"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-bell text-purple-500"
            ></i>
            <span>Notifications</span>
          </div>
        </div>

        <!-- Bidlines -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="enableBidlines"
          @click="handleNavigateToBidlines"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-calendar-range text-green-600"
            ></i>
            <span>Bid Lines</span>
          </div>
        </div>

        <!-- Administration -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="enableAdministration"
          @click="handleNavigateToAdministration"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-user-group text-blue-700"
            ></i>
            <span>Administration</span>
          </div>
        </div>

        <!-- Activity Reports -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="enableActivityReports"
          @click="handlenavigateToActivityReports"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-light fa-file-invoice-dollar text-green-600"
            ></i>
            <span>Activity Reports</span>
          </div>
        </div>

        <!-- Security -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="enableSecurityGroups"
          @click="handleNavigateToSecurityGroups"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-shield-quartered text-red-500"
            ></i>
            <span>Security Groups</span>
          </div>
        </div>

        <!-- Airports -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="enableAirports"
          @click="handleNavigateToAirports"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-tower-control text-blue-500"
            ></i>
            <span>Airports</span>
          </div>
        </div>

        <!-- Aircraft -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="enableAircraft"
          @click="handleNavigateToAircraft"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-regular fa-plane-tail text-blue-900"
            ></i>
            <span>Aircraft</span>
          </div>
        </div>

        <!-- Crew Requirements -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="enableCrewRequirements"
          @click="handleNavigateToCrewRequirements"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-user-pilot text-blue-500"
            ></i>
            <span>Crew Requirements</span>
          </div>
        </div>

        <!-- My Monarch -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="enableMyMonarch"
          @click="handleNavigateToMyMonarch"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-user text-green-600"
            ></i>
            <span>My Monarch</span>
          </div>
        </div>

        <!-- About -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="user"
          @click="handleNavigateToAbout"
        >
          <div class="title">
            <div
              class="bg-cover bg-center bg-[url(../assets/monarch_logo_mini.png)] h-16 w-16"
            ></div>
            <div>
              <div class="txt-lg lg:text-xl">About Monarch</div>
              <div class="txt-sm lg:text-lg">{{ `Version ${version}` }}</div>
            </div>
          </div>
        </div>

        <!-- Manual -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="user"
          @click="handleShowManual"
        >
          <div class="title text-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-book text-blue-500"
            ></i>
            <span>Manuals</span>
          </div>
        </div>
      </div>
    </div>
    <footer class="text-sm lg:text-base" :class="{ disabled: !user }">
      {{ disclaimer }}
    </footer>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../services/api";

import { mixin } from "../mixins/mixin";
import { PDFDocument } from "pdf-lib";
import LoginWarningsModal from "../components/LoginWarningsModal.vue";
import RSpinner from "../components/RSpinner.vue";
import RSnackbar from "../components/RSnackbar.vue";

export default {
  name: "home",
  mixins: [mixin],
  components: { LoginWarningsModal, RSpinner, RSnackbar },
  data() {
    return {
      inactivityTimer: null,
      isLoginInProgress: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      loading: false,
      employee: null,
      showLoginWarningsModal: false,
      errorMessage: null,
      disclaimer: `Flight schedule information and other related station and aircraft details are made available for the convenience of Atlas Air and Vendor Staff. Atlas Air cannot and does not guarantee that all information is current, complete or totally accurate at any given time. While we strive to provide complete, accurate and near real-time flight and schedule information, reliance on this software and the flight details herein is solely at the users own risk and Atlas Air disclaims any warranty or indemnity involved with the use of the software, whether express or implied.`,
    };
  },
  created() {
    (async () => {
      aad.initMsal();
    })();
  },

  mounted() {
    this.startInactivityTimer();
    document.addEventListener("click", this.resetInactivityTimer);
  },
  unmounted() {
    this.clearInactivityTimer();
    document.removeEventListener("click", this.resetInactivityTimer);
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    assignmentsNotAcknowledged() {
      return this.$store.getters.assignmentsNotAcknowledged;
    },

    enableFlightBoard() {
      if (!this.user || this.assignmentsNotAcknowledged.length) {
        return false;
      }

      return true;
    },

    enableBidlines() {
      if (!this.user || this.assignmentsNotAcknowledged.length) {
        return false;
      }

      return this.canCreateBidlines;
    },

    enableNotifications() {
      if (!this.user || this.assignmentsNotAcknowledged.length) {
        return false;
      }

      return (
        this.canCreateAssignmentNotification &&
        this.canReadAllAssignmentNotification
      );
    },

    enableAdministration() {
      if (!this.user || this.assignmentsNotAcknowledged.length) {
        return false;
      }

      return this.canCreateEmployee;
    },

    enableAssignmentBoard() {
      if (!this.user || this.assignmentsNotAcknowledged.length) {
        return false;
      }

      return this.canReadFlightAssignments || this.canReadGroundAssignments;
    },

    enableSecurityGroups() {
      if (!this.user || this.assignmentsNotAcknowledged.length) {
        return false;
      }

      return this.canCreateSecurityGroups;
    },

    enableActivityReports() {
      if (!this.user || this.assignmentsNotAcknowledged.length) {
        return false;
      }

      return (
        this.canReadManagerPayrollReport ||
        this.canReadAdministratorPayrollReport
      );
    },

    enableMyMonarch() {
      if (!this.user) {
        return false;
      }

      return this.canUpdateEmployee;
    },

    enableAirports() {
      if (!this.user || this.assignmentsNotAcknowledged.length) {
        return false;
      }

      return this.canUpdateAirport;
    },

    enableAircraft() {
      if (!this.user || this.assignmentsNotAcknowledged.length) {
        return false;
      }

      return this.canUpdateAircraft;
    },

    enableCrewRequirements() {
      if (!this.user || this.assignmentsNotAcknowledged.length) {
        return false;
      }

      return this.canUpdateCrewRequirements;
    },
  },
  methods: {
    startInactivityTimer() {
      this.clearInactivityTimer();
      this.inactivityTimer = setTimeout(() => {
        this.handleLogout();
      }, this.timeoutDuration);
    },

    clearInactivityTimer() {
      if (this.inactivityTimer) {
        clearTimeout(this.inactivityTimer);
      }
    },

    resetInactivityTimer() {
      this.startInactivityTimer();
    },

    async handleLogout() {
      clearTimeout(this.inactivityTimer);
      await aad.logout();
    },

    handleResetErrorMessage() {
      this.errorMessage = null;
    },

    async getLoginHelp() {
      this.errorMessage = null;
      this.loading = true;

      try {
        const url =
          "https://s3.amazonaws.com/monarch.atlasair.com.resources/monarch_login_help.pdf";
        const existingPdfBytes = await fetch(url).then((res) =>
          res.arrayBuffer()
        );

        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        pdfDoc.setTitle("Monarch Login Help");

        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");

        this.loading = false;
      } catch (error) {
        this.loading = false;

        this.errorMessage = error.message;
      }
    },

    async handleLogin() {
      if (this.errorMessage || this.isLoginInProgress) {
        this.errorMessage = `To ensure a smooth login process, please make sure you fully log out of your account before attempting to log in again. This means completing the logout process entirely to avoid any conflicts with your previous session. Please close this page, reopen it, and start the login process from the beginning.`;
        return;
      }

      this.isLoginInProgress = true;
      this.errorMessage = null;
      this.loading = true;

      try {
        //Get auth from Active Directory
        const authResult = await aad.login();

        if (authResult.status === "Failed") {
          this.errorMessage = `To ensure a smooth login process, please make sure you fully log out of your account before attempting to log in again. This means completing the logout process entirely to avoid any conflicts with your previous session. Please close this page, reopen it, and start the login process from the beginning.`;
          return;
        }

        const account = authResult.account;

        //Continue only if account exists in Active Directory
        if (account) {
          const roles = authResult.account.idTokenClaims.roles;

          if (!roles.length) {
            this.loading = false;
            this.errorMessage = `Not authorized to use Monarch`;
            return;
          }

          const dt1 = new Date();
          const yy = dt1.getUTCFullYear();
          const mm = dt1.getUTCMonth();
          const dd = dt1.getUTCDate();

          const filterStart = new Date(
            Date.UTC(yy, mm, dd, 0, 0, 0, 0)
          ).getTime();

          //Login to server with AD token
          const token = await aad.getIdTokenSilent();

          let res = await api.get(`/employees/login`, {
            headers: {
              Authorization: "Bearer " + token.idToken,
            },
          });

          this.loading = false;

          if (res.status !== 200) {
            this.errorMessage = "Server error";
            return;
          }

          let employee = res.data.employee;

          employee.warnings = this.getGeneralWarnings([employee]);
          employee.assignments = res.data.assignments;

          if (employee.assignments.length) {
            const assignmentsNotAcknowledged = employee.assignments.filter(
              (item) => {
                return item.notification?.acknowledged === false;
              }
            );

            if (assignmentsNotAcknowledged.length) {
              this.$store.commit(
                "updateAssignmentsNotAcknowledged",
                assignmentsNotAcknowledged
              );

              employee.warnings.unshift({
                level: "Hard",
                type: "Assignment Notifications",
                message: `You have ${assignmentsNotAcknowledged.length} assignment notification(s) that must be acknowledge to continue.`,
              });
            }
          } else {
            this.$store.commit("updateAssignmentsNotAcknowledged", []);
          }

          //Add appRole to employee
          const mainRole = roles[0];
          const i = mainRole.indexOf(".com");

          employee.appRole = mainRole.substr(i + 5, mainRole.length);

          const aircraft = res.data.aircraft;
          const airports = res.data.airports;
          const crewRequirementExemptions = res.data.crewRequirementExemptions;
          const securityGroups = res.data.securityGroups;

          if (
            !crewRequirementExemptions ||
            !aircraft?.length ||
            !airports?.length ||
            !securityGroups?.length
          ) {
            this.loading = false;
            this.errorMessage = `Unable to load data`;
            return;
          }

          this.$store.commit("updateDateFilter", {
            filterStart,
            numberOfDays: 7,
          });

          this.$store.commit("updateMonthFilter", {
            year: yy,
            month: mm,
            period: "Second",
          });

          this.$store.commit(
            "updateCrewRequirementExemptions",
            crewRequirementExemptions
          );

          this.$store.commit("updateAircraft", aircraft);
          this.$store.commit("updateAirports", airports);
          this.$store.commit("updateUser", employee);
          this.$store.commit("updateSecurityGroups", securityGroups);

          let aircraftFilter = {
            active: false,
            aircraft: [],
          };
          if (employee.aircraftFilter.active) {
            const _aircraft = aircraft.filter((a) => {
              return employee.aircraftFilter.registrations.includes(
                a.registration
              );
            });

            aircraftFilter = {
              active: true,
              aircraft: _aircraft,
            };
          }

          this.$store.commit("updateFlightBoardAircraftFilter", aircraftFilter);

          this.$store.commit("updateJobTitlesFilter", employee.jobTitlesFilter);
          this.$store.commit("updateCompanyFilter", employee.companyFilter);

          if (employee.warnings.length) {
            this.employee = employee;
            this.showLoginWarningsModal = true;
          }

          this.isLoginInProgress = false;
        } else {
          this.errorMessage = `Active Directory account not found, please contact your administrator`;
        }

        // Initial timer start
        this.startInactivityTimer();
      } catch (error) {
        this.errorMessage = `Error during login: ${error}`;
      } finally {
        this.loading = false;
      }
    },

    handleCloseLoginWarningsModal(e) {
      this.showLoginWarningsModal = false;

      if (e.action === "acknowledge") {
        if (this.assignmentsNotAcknowledged.length) {
          this.$router.push({ name: "myMonarch" });
        }
      } else {
        this.handleLogout();
      }
    },

    handleShowManual() {
      this.$router.push({ name: "manuals" });
    },

    handleNavigateToMyMonarch() {
      this.$router.push({ name: "myMonarch" });
    },

    handleNavigateToFlightBoard() {
      this.$router.push({ name: "flightBoard" });
    },

    handleNavigateToAssignmentBoard() {
      this.$store.commit("updateAssignmentData", null);

      this.$router.push({ name: "assignmentBoard" });
    },

    handleNavigateToNotifications() {
      this.$router.push({ name: "notifications" });
    },

    handleNavigateToAdministration() {
      this.$router.push({ name: "administration" });
    },

    handleNavigateToBidlines() {
      this.$router.push({ name: "bidlines" });
    },

    handlenavigateToActivityReports() {
      this.$router.push({ name: "activityReports" });
    },

    handleNavigateToAirports() {
      this.$router.push({ name: "airports" });
    },

    handleNavigateToAircraft() {
      this.$router.push({ name: "aircraft" });
    },

    handleNavigateToCrewRequirements() {
      this.$router.push({ name: "crewRequirements" });
    },

    handleNavigateToSecurityGroups() {
      this.$router.push({ name: "securityGroups" });
    },

    handleNavigateToAbout() {
      this.$router.push({ name: "about" });
    },

    async getLoginManual() {
      const url =
        "https://s3.amazonaws.com/monarch.atlasair.com.resources/monarch_login_help.pdf";
      const existingPdfBytes = await fetch(url).then((res) =>
        res.arrayBuffer()
      );

      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      pdfDoc.setTitle("Monarch Login Help");

      const pdfUrl = URL.createObjectURL(
        new Blob([await pdfDoc.save()], { type: "application/pdf" })
      );
      window.open(pdfUrl, "_blank");
    },
  },
};
</script>

<style scoped>
/* ------------------------------------------ Tiles */

.tile {
  box-sizing: border-box;
  background-color: aliceblue;
  border: 1px solid gray;
  /* border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1); */
  padding: 10px;
  color: black;
  cursor: pointer;
}

.tile .title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
}

.tile span {
  margin-left: 10px;
}

.tile:hover {
  background-color: rgb(201, 199, 199);
}

.monarch-logo {
  background-image: url(../assets/monarch_logo.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
  width: 113px;
  margin: 5px;
}

.giant-logo {
  background-image: url(../assets/atlas_logo_giant.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 45px;
  width: 128px;
  margin: 5px;
}

.monarch-logo-mini {
  background-image: url(../assets/monarch_logo_mini.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 30px;
  width: 24px;
}

.content {
  margin: 10px 2%;
  padding: 10px;
}

header {
  margin: 10px 2%;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: black;
}

footer {
  padding: 10px;
  background-color: #002663;
  color: white;
}
</style>
