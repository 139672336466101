<template>
  <div>
    <!-- #region NAVBAR -->
    <nav>
      <ul class="nav-wrapper">
        <li class="icon" @click="handleGoBack">
          <a href="#">
            <span class="tooltip">Go Back</span>
            <span><i class="fa-solid fa-arrow-left text-white"> </i></span>
          </a>
        </li>

        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">Assignments</div>
          </a>
        </li>

        <li>
          <a href="#">
            <div
              v-if="boardStyle === 'Board' && pages.length"
              class="flex justify-between fnt-sm"
            >
              <button class="btn-icon" @click="handlePageUp">
                <i class="fa-solid fa-chevrons-up text-white"></i>
              </button>
              <div class="text-white">
                {{ `${pageNumber + 1} of ${pages.length}` }}
              </div>

              <button class="btn-icon" @click="handlePageDown">
                <i class="fa-solid fa-chevrons-down text-white"></i>
              </button>
            </div>
          </a>
        </li>

        <li>
          <a href="#">
            <input
              @click="[(search = ''), filterEmployeesSchedule()]"
              class="mx-3 text-align-center w-24 h-8 rounded-md border-gray-500"
              v-model="search"
              @input="filterEmployeesSchedule()"
              type="text"
              maxlength="6"
            />
          </a>
        </li>

        <li @click="handleOpenFilters" class="icon">
          <a href="#">
            <span class="tooltip">Employee Filters</span>
            <span><i class="fa-solid fa-bars-filter text-yellow-500"></i></span>
          </a>
        </li>

        <li class="icon" @click="handleShowWarnings">
          <a href="#">
            <span class="tooltip">{{ `Warnings ${warnings.length}` }}</span>
            <span
              ><i class="fa-solid fa-triangle-exclamation text-orange-500"></i
            ></span>
          </a>
        </li>

        <li v-if="assignmentData" @click="handleRemoveTarget" class="icon">
          <a href="#">
            <span class="tooltip">Hide Assignment Shadow</span>
            <span><i class="fa-solid fa-square text-blue-500"></i></span>
          </a>
        </li>

        <li @click="handleToggleBoardStyle" class="icon">
          <a href="#">
            <span class="tooltip">Switch Board</span>
            <span><i class="fa-solid fa-bolt text-yellow-500"></i></span>
          </a>
        </li>
        <li @click="specialAirportsDialog = true" class="icon">
          <a href="#">
            <span class="tooltip">Special Airports</span>
            <span><i class="fa-solid fa-tower-control text-blue-500"></i></span>
          </a>
        </li>

        <li @click="zoomIn" class="icon" v-if="boardStyle !== 'List'">
          <a href="#">
            <span class="tooltip">Zoom In</span>
            <span><i class="fa-regular fa-magnifying-glass-plus"></i></span>
          </a>
        </li>
        <li @click="zoomOut" class="icon" v-if="boardStyle !== 'List'">
          <a href="#">
            <span class="tooltip">Zoom Out</span>
            <span><i class="fa-regular fa-magnifying-glass-minus"></i></span>
          </a>
        </li>
        <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>

        <li
          v-if="assignmentData"
          @click="handleToggleStationFilter"
          class="icon"
        >
          <a href="#">
            <span class="tooltip">Filter By Station </span>
            <span
              ><i
                class="fa-sharp fa-solid fa-location-dot text-white cursor-pointer ml-5"
                :style="getFilterByStationStyle()"
              ></i
            ></span>
          </a>
        </li>
      </ul>
    </nav>

    <!-- #endregion -->

    <!-- #region WARNINGS -->
    <!-- SNACKBAR -->
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- ASSIGNMENT WARNINGS -->

    <r-modal
      v-if="showAssigmentWarningsModal"
      @close="handleCloseAssignmentWarningsModal(false)"
    >
      <div class="border-l-8 border-2 border-red-500 p-2">
        <!-- TITLE -->
        <div class="flex justify-between">
          <div class="text-2xl text-red-500">Assignment Warnings</div>
          <button
            @click="handleCloseAssignmentWarningsModal(false)"
            class="btn-icon"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="px-5">
          <div class="text-2xl text-blue-500 font-bold">
            {{ `${employee.surname}, ${employee.givenName}` }}
          </div>
          <div>
            {{
              `Warnings: ${hardWarnings.length} hard, ${
                assignmentWarnings.length - hardWarnings.length
              } soft.`
            }}
          </div>
        </div>
        <section>
          <div
            class="warning-tile"
            :style="getWarningStyle(warning.level)"
            v-for="(warning, index) in assignmentWarnings"
            :key="index"
          >
            <div class="flex justify-between font-2xl font-bold">
              <div>
                {{ warning.type }}
              </div>
              <i
                v-if="warning.level === 'Hard'"
                class="fa-solid fa-octagon-exclamation text-red-500"
              ></i>
              <i v-else class="fa-solid fa-warning text-orange-500"></i>
            </div>

            <div class="text-sm">{{ warning.message }}</div>
          </div>
          <div class="text-sm color-gray my-2">
            {{
              `Training Records updated at: ${formatDateTime(
                this.employee.trainingRecords.updatedAt
              )} UTC`
            }}
          </div>
        </section>

        <div v-if="hardWarnings.length">
          <div
            v-if="canOverrideWarnings"
            class="flex justify-between px-4 py-2"
          >
            <div class="text-sm text-red-500">
              By clicking acknowledge, you confirm that you have read and
              understood these warnings.
            </div>
            <button
              @click="handleCloseAssignmentWarningsModal(true)"
              class="btn bg-blue-500 text-white"
            >
              Acknowledge
            </button>
          </div>
          <div v-else class="flex justify-between px-4 py-2">
            <div class="text-sm text-red-500">
              You do not have the required permission to continue.
            </div>
            <button
              @click="handleCloseAssignmentWarningsModal(false)"
              class="btn bg-gray-500 text-white"
            >
              Cancel
            </button>
          </div>
        </div>
        <div v-else class="flex justify-between px-4 py-2">
          <div class="text-sm text-red-500">
            By clicking acknowledge, you confirm that you have read and
            understood these warnings.
          </div>

          <button
            @click="handleCloseAssignmentWarningsModal(true)"
            class="btn bg-blue-500 text-white"
          >
            Acknowledge
          </button>
        </div>
      </div>
    </r-modal>

    <r-modal v-if="showWarningsDialog" @close="showWarningsDialog = false">
      <div class="border-l-8 border-2 border-red-500 p-2">
        <!-- TITLE -->
        <div class="flex justify-between">
          <div class="text-2xl font-bold mb-2">Warnings</div>
          <button @click="showWarningsDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <!-- WARNINGS -->

        <div class="max-h-[300px] lg:max-h-[500px] relative overflow-x-auto">
          <table
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortWarningsByKey('employeeName')"
                >
                  Employee
                </th>
                <th
                  style="width: 10%"
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortWarningsByKey('type')"
                >
                  Type
                </th>
                <th
                  style="width: 20%"
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortWarningsByKey('level')"
                >
                  Level
                </th>
                <th
                  style="width: 50%"
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortWarningsByKey('message')"
                >
                  Message
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="warning in filteredWarnings"
                :key="warning._id"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td
                  scope="row"
                  class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ warning.employeeName }}
                </td>
                <td class="px-6 py-2">{{ warning.type }}</td>
                <td class="px-6 py-2">
                  {{ warning.level }}
                </td>

                <td class="px-6 py-2">
                  {{ warning.message }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </r-modal>

    <!-- #endregion -->

    <!-- #region LOADING -->

    <r-spinner v-if="loading"> </r-spinner>
    <!-- #endregion -->

    <!-- #region DIALOGS -->
    <!--  FILTERS DIALOG -->
    <r-modal v-if="showFiltersDialog" @close="showFiltersDialog = false">
      <div v-if="showFiltersDialog" class="p-5">
        <!-- Header -->
        <div class="flex justify-between mb-1">
          <div class="text-2xl font-bold ml-5">Employee Filters</div>
          <button @click="showFiltersDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div
          class="flex flex-col justify-between text-sm px-3 max-h-[300px] lg:max-h-[500px] overflow-x-auto"
        >
          <!-- Section -->
          <div class="px-5">
            <!-- Filter by Aircraft Deck -->
            <div class="border-b border-gray-400 mb-5">
              <div class="flex h-6 items-center mb-2">
                <input
                  v-model="filterByAircraftDeck"
                  id="filterByAircraftDeck"
                  aria-describedby="filter-description"
                  name="filterByAircraftDeck"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                />

                <div class="ml-3 text-base leading-6">
                  <label
                    for="filterByAircraftDeck"
                    class="font-medium text-gray-900"
                    >Filter by Aircraft Deck</label
                  >
                  {{ " " }}
                </div>
              </div>

              <div class="flex my-1 px-5 pb-2" v-if="filterByAircraftDeck">
                <Listbox as="div" v-model="selectedAircraftDeck" class="mb-10">
                  <ListboxLabel
                    class="block text-sm font-medium leading-6 text-gray-900"
                    >Show Employees when job title is associated
                    with</ListboxLabel
                  >
                  <div class="relative mt-2 min-w-[250px]">
                    <ListboxButton
                      class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                      <span class="block truncate">{{
                        selectedAircraftDeck
                      }}</span>
                      <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                      >
                        <ChevronUpDownIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition ease-in duration-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        <ListboxOption
                          as="template"
                          v-for="(option, id) in aircraftDeckOptions"
                          :key="id"
                          :value="option"
                          v-slot="{ active, selectedAircraftDeck }"
                        >
                          <li
                            :class="[
                              active
                                ? 'bg-blue-600 text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                            ]"
                          >
                            <span
                              :class="[
                                selectedAircraftDeck
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'block truncate',
                              ]"
                              >{{ option }}</span
                            >

                            <span
                              v-if="selectedAircraftDeck"
                              :class="[
                                active ? 'text-white' : 'text-blue-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>
            </div>

            <!-- Filter by Company Name -->
            <div class="border-b border-gray-400 mb-5">
              <div class="flex h-6 items-center mb-2">
                <input
                  v-model="filterByCompany"
                  id="filterByCompany"
                  aria-describedby="filter-description"
                  name="filterByCompany"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                />

                <div class="ml-3 text-base leading-6">
                  <label for="filterByCompany" class="font-medium text-gray-900"
                    >Filter by Company</label
                  >
                  {{ " " }}
                </div>
              </div>

              <div class="flex my-1 px-5 pb-2" v-if="filterByCompany">
                <Listbox as="div" v-model="selectedCompany" class="mb-10">
                  <ListboxLabel
                    class="block text-sm font-medium leading-6 text-gray-900"
                    >Show Employees from this Company</ListboxLabel
                  >
                  <div class="relative mt-2 min-w-[250px]">
                    <ListboxButton
                      class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                      <span class="block truncate">{{ selectedCompany }}</span>
                      <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                      >
                        <ChevronUpDownIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition ease-in duration-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        <ListboxOption
                          as="template"
                          v-for="(option, id) in companyOptions"
                          :key="id"
                          :value="option"
                          v-slot="{ active, selectedCompany }"
                        >
                          <li
                            :class="[
                              active
                                ? 'bg-blue-600 text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                            ]"
                          >
                            <span
                              :class="[
                                selectedCompany
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'block truncate',
                              ]"
                              >{{ option }}</span
                            >

                            <span
                              v-if="selectedCompany"
                              :class="[
                                active ? 'text-white' : 'text-blue-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>
            </div>

            <!-- Filter by Station IATA -->
            <div class="border-b border-gray-400 mb-5">
              <div class="flex h-6 items-center mb-2">
                <input
                  v-model="filterByAirport"
                  id="filterByIata"
                  aria-describedby="filter-description"
                  name="filterByIata"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                />

                <div class="ml-3 text-base leading-6">
                  <label for="filterByIata" class="font-medium text-gray-900"
                    >Filter by Station IATA</label
                  >
                  {{ " " }}
                </div>
              </div>

              <div class="flex my-1 px-5 pb-2" v-if="filterByAirport">
                <Listbox as="div" v-model="selectedIataMatch" class="mb-10">
                  <ListboxLabel
                    class="block text-sm font-medium leading-6 text-gray-900"
                    >Show Employees with Assignments</ListboxLabel
                  >
                  <div class="relative mt-2 min-w-[250px]">
                    <ListboxButton
                      class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                      <span class="block truncate">{{
                        selectedIataMatch
                      }}</span>
                      <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                      >
                        <ChevronUpDownIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition ease-in duration-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="absolute z-10 mt-1 max-h-80 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        <ListboxOption
                          as="template"
                          v-for="(option, id) in iataMatchOptions"
                          :key="id"
                          :value="option"
                          v-slot="{ active, selectedIataMatch }"
                        >
                          <li
                            :class="[
                              active
                                ? 'bg-blue-600 text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                            ]"
                          >
                            <span
                              :class="[
                                selectedIataMatch
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'block truncate',
                              ]"
                              >{{ option }}</span
                            >

                            <span
                              v-if="selectedIataMatch"
                              :class="[
                                active ? 'text-white' : 'text-blue-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>

                <div class="ml-3">
                  <label
                    for="iata"
                    class="block text-sm font-medium leading-6 text-gray-900"
                    >IATA</label
                  >
                  <div class="mt-2">
                    <input
                      type="text"
                      v-model="selectedIataCode"
                      placeholder="iata"
                      maxlength="3"
                      name="iata"
                      id="iata"
                      class="min-h-9 width-block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Filter by Job Title -->
            <div class="border-b border-gray-400 mb-5">
              <div class="mb-2">
                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="filterByJobTitle"
                    id="filterByJobTitle"
                    aria-describedby="filter-description"
                    name="filterJobTitle"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-base leading-6">
                    <label
                      for="filterByJobTitle"
                      class="font-medium text-gray-900"
                      >Filter by Job Title</label
                    >
                    {{ " " }}
                  </div>
                </div>

                <div class="flex mt-2" v-if="filterByJobTitle">
                  <div
                    v-if="selectedJobTitles.length !== jobTitles.length"
                    @click="selectedJobTitles = [...jobTitles]"
                    class="cursor-pointer text-base underline text-blue-500"
                  >
                    Selected All
                  </div>
                  <div
                    v-if="selectedJobTitles.length"
                    @click="selectedJobTitles = []"
                    class="ml-2 cursor-pointer text-base underline text-red-500"
                  >
                    Clear Selected
                  </div>
                </div>
              </div>

              <div v-if="filterByJobTitle">
                <div class="relative shadow-md">
                  <table
                    class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    <tbody>
                      <tr
                        v-for="jobTitle in jobTitles"
                        :key="jobTitle.name"
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td class="w-4 p-4">
                          <div class="flex items-center">
                            <input
                              v-model="selectedJobTitles"
                              :id="jobTitle.name"
                              :value="jobTitle"
                              type="checkbox"
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>
                        </td>
                        <td
                          scope="row"
                          class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {{ jobTitle }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- Footer -->
          <div class="flex justify-end mt-1">
            <button
              @click="handleSaveFilters"
              class="btn bg-blue-500 text-white"
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- DATE FILTER DIALOG -->
    <r-modal v-if="showDateFilterDialog" @close="showDateFilterDialog = false">
      <div v-if="showDateFilterDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Date Filter</div>
          <button @click="showDateFilterDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex">
          <div>
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Start Date</label
            >

            <input
              class="block w-32 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="startDate"
              type="date"
              id="date-filter"
            />
          </div>

          <div class="ml-3">
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Number of Days</label
            >

            <select
              class="block w-24 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="numberOfDays"
            >
              <option
                v-for="option in numberOfDaysOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex justify-end mt-1">
          <button
            @click="handleSaveDateFilter"
            class="btn bg-blue-500 text-white"
          >
            Apply Filter
          </button>
        </div>
      </div>
    </r-modal>

    <!-- SPECIAL AIRPORTS DIALOG -->
    <r-modal v-if="specialAirportsDialog">
      <SpecialAirports @hide="specialAirportsDialog = false" />
    </r-modal>

    <!-- EMPLOYEE INFORMATION DIALOG -->
    <r-modal
      v-if="showEmployeeInformationDialog"
      @close="showEmployeeInformationDialog = false"
    >
      <employee-information
        :employee="employee"
        @close="showEmployeeInformationDialog = false"
        @employeeProfileButtonClick="handleNavigateToEmployeeProfile"
      ></employee-information>
    </r-modal>

    <!-- STATION ASSIGNMENT INFORMATION DIALOG -->
    <r-modal
      v-if="showStationAssignmentInformationDialog"
      @close="showStationAssignmentInformationDialog = false"
    >
      <station-assignment-information
        v-if="stationAssignment"
        :groundAssignment="stationAssignment"
        @close="hideStationAssignmentInformationDialog($event)"
      >
      </station-assignment-information>
    </r-modal>

    <!-- NEW TRIP ASSIGNMENT DIALOG -->
    <r-modal
      v-if="showNewTripAssignmentDialog"
      @close="showNewTripAssignmentDialog = false"
    >
      <div class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold text-blue-500 mr-2">
            TRIP ASSIGNMENT
          </div>
          <button @click="showNewTripAssignmentDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="text-2xl font-bold">
          {{ employee.surname }}, {{ employee.givenName }}
        </div>

        <div class="text-xl">
          {{
            `Segments: ${
              assignmentData.trip.flights.length
            },  Total Duty: ${formatElapsedTime(assignmentData.trip.duration)}`
          }}
        </div>

        <div class="horizontal-line-blue my-3"></div>

        <div class="vertical-scroller-400">
          <div v-for="flight in assignmentData.trip.flights" :key="flight._id">
            <div class="flex justify-between py-1">
              <div>{{ formatFlightSnapshot(flight) }}</div>
              <button
                class="btn-text text-blue-500"
                @click="handleSetTripRole(flight._id)"
              >
                {{ getTripFlightRole(flight._id) }}
              </button>
            </div>

            <div class="horizontal-line-gray"></div>
          </div>
          <!-- ASSIGNMENT REMARKS -->
          <div class="text-small text-gray-500">Remarks</div>

          <div class="w-full">
            <div class="mb-2">
              <textarea
                v-model="remarks"
                rows="4"
                name="remarks"
                id="remarks"
                class="block w-full rounded-md border-0 py-1.5 text-base text-blue-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
              />
            </div>
          </div>
        </div>

        <div class="flex justify-end mt-2">
          <!-- Role Selector -->
          <div class="flex max-width-300px mr-3">
            <select
              class="block w-48 py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedRole"
            >
              <option
                v-for="option in authorizedFlightRoles"
                :key="option"
                :value="option"
                :style="{ width: '300px' }"
              >
                {{ option }}
              </option>
            </select>
          </div>

          <div>
            <div>
              <button
                @click="handleSaveTripAssignment"
                class="btn bg-blue-500 text-white"
              >
                Assign Trip
              </button>
            </div>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- NEW STATION ASSIGNMENT DIALOG -->
    <r-modal
      v-if="showNewStationAssignmentDialog"
      @close="showNewStationAssignmentDialog = false"
    >
      <div class="p-5">
        <div class="flex justify-between mb-2">
          <div>
            <div class="text-2xl font-bold text-blue-500 mr-2">
              {{
                `${assignmentData.stationAssignment.originIata} - STATION ASSIGNMENT`
              }}
            </div>
          </div>
          <button
            @click="showNewStationAssignmentDialog = false"
            class="btn-icon"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="text-2xl font-bold">
          {{ employee.surname }}, {{ employee.givenName }}
        </div>

        <!-- <div class="text-xl">
          {{
            `Assignments: ${
              assignmentData.stationAssignment.flights.length
            },  Total Duty: ${formatElapsedTime(
              assignmentData.stationAssignment.duration
            )}`
          }}
        </div> -->

        <!-- Start / End Times -->
        <div class="flex my-3 border-t border-b border-blue-500 pb-2">
          <div>
            <p class="ml-2 text-sm text-gray-500">Start</p>

            <div class="flex">
              <input
                class="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                v-model="startDate"
                type="date"
              />
              <div>
                <input
                  class="ml-2 block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  v-model="startTime"
                  type="time"
                />
              </div>
            </div>
          </div>

          <div class="mx-5"></div>

          <div>
            <p class="ml-2 text-sm text-gray-500">End</p>
            <div class="flex">
              <input
                class="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                v-model="endDate"
                type="date"
              />

              <div>
                <input
                  class="ml-2 block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  v-model="endTime"
                  type="time"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="vertical-scroller-400">
          <div
            v-for="flight in assignmentData.stationAssignment.flights"
            :key="flight._id"
          >
            <div>{{ formatFlightSnapshot(flight) }}</div>
            <div class="horizontal-line-gray"></div>
          </div>
          <!-- ASSIGNMENT REMARKS -->
          <div class="text-small text-gray-500">Remarks</div>

          <div class="w-full">
            <div class="mb-2">
              <textarea
                v-model="remarks"
                rows="4"
                name="remarks"
                id="remarks"
                class="block w-full rounded-md border-0 py-1.5 text-base text-blue-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
              />
            </div>
          </div>
        </div>

        <div class="flex justify-end mt-2">
          <!-- Role Selector -->
          <div class="flex max-width-300px mr-3">
            <select
              class="block w-48 py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedRole"
            >
              <option
                v-for="option in authorizedFlightRoles"
                :key="option"
                :value="option"
                :style="{ width: '300px' }"
              >
                {{ option }}
              </option>
            </select>
          </div>

          <div>
            <div>
              <button
                @click="handleSaveStationAssignment"
                class="btn bg-blue-500 text-white"
              >
                Assign
              </button>
            </div>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- #endregion -->

    <!-- #region FLIGHT ASSIGNMENTS -->
    <!-- FLIGHT ASSIGNMENT INFORMATION DIALOG -->

    <!-- NEW FLIGHT ASSIGNMENT DIALOG -->
    <r-modal
      v-if="showNewFlightAssignmentDialog"
      @close="showNewFlightAssignmentDialog = false"
    >
      <div class="p-5 bg-blue-200">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">
            {{
              ` ${employee.surname}, ${employee.givenName} -
            ${employee.companyName}`
            }}
          </div>
          <button
            @click="showNewFlightAssignmentDialog = false"
            class="btn-icon"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="text-base my-1">
          {{ formatFlightSnapshot(assignmentData.flight) }}
        </div>
        <div class="vertical-scroller-400">
          <!-- CREWMEMBERS -->
          <div>
            <!-- FLIGHT CREWMEMBERS -->
            <div>Flight Crewmembers</div>
            <div v-if="assignmentData.flight.crewmembers.length">
              <div
                v-for="crewmember in assignmentData.flight.crewmembers"
                :key="crewmember._id"
              >
                <div
                  v-if="crewmember.employee"
                  class="flex justify-between align-center text-xs"
                >
                  <div class="flex w-full border-b border-gray-500 py-1">
                    <div>{{ crewmember.role }}:</div>
                    <div class="ml-2">
                      {{ crewmember.employee.surname }},
                      {{ crewmember.employee.givenName }}
                    </div>
                  </div>
                </div>
                <div v-else class="flex justify-between align-center text-xs">
                  <div class="flex w-full border-b border-gray-500 py-1">
                    <div>{{ crewmember.role }}:</div>
                    <div class="ml-2 text-red-500">
                      {{ crewmember.assignedToCompany }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="text-xs text-red-500">
              No flight crewmembers assigned
            </div>
          </div>

          <div class="horizontal-line-blue my-2"></div>

          <!-- GROUND CREWMEMBERS -->
          <div class="mt-2">
            <div>Ground Crewmembers</div>
            <div
              class="mb-1"
              v-if="assignmentData.flight.groundCrewmembers.length > 0"
            >
              <div
                v-for="groundCrewmember in assignmentData.flight
                  .groundCrewmembers"
                :key="groundCrewmember._id"
              >
                <div
                  v-if="groundCrewmember.employee"
                  class="flex justify-between align-center"
                >
                  <div class="flex text-xs">
                    <div>
                      {{
                        `${groundCrewmember.role} (${groundCrewmember.originIata})`
                      }}:
                    </div>
                    <div class="ml-2">
                      {{ groundCrewmember.employee.surname }},
                      {{ groundCrewmember.employee.givenName }}
                    </div>
                  </div>
                </div>
                <div v-else class="flex justify-between align-center">
                  <div class="flex">
                    <div>
                      {{
                        `${groundCrewmember.role} (${groundCrewmember.originIata})`
                      }}:
                    </div>
                    <div>
                      {{ groundCrewmember.assignedToCompany }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="text-xs text-red-500">
              No ground crewmembers assigned
            </div>
          </div>

          <div class="horizontal-line-blue my-2"></div>

          <!-- ASSIGNMENT REMARKS -->
          <div class="text-small text-gray-500">Remarks</div>

          <div class="w-full">
            <div class="mb-2">
              <textarea
                v-model="remarks"
                rows="4"
                name="remarks"
                id="remarks"
                class="block w-full rounded-md border-0 py-1.5 text-base text-blue-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
              />
            </div>
          </div>
        </div>

        <div class="flex justify-end mt-2">
          <!-- Role Selector -->
          <div class="flex mr-3">
            <select
              class="block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="selectedRole"
            >
              <option
                v-for="option in authorizedFlightRoles"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>

          <div>
            <button
              class="btn bg-blue-500 text-white"
              @click="handleSaveFlightAssignment"
            >
              Assign
            </button>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- FLIGHT ASSIGNMENT INFOMATION -->
    <r-modal
      v-if="showFlightAssignmentInformationDialog"
      @close="showFlightAssignmentInformationDialog = false"
    >
      <FlightAssignmentInformation
        v-if="flightAssignment"
        :flightAssignment="flightAssignment"
        @close="hideFlightAssignmentInformationDialog($event)"
      ></FlightAssignmentInformation>
    </r-modal>

    <!-- #endregion -->

    <!-- #region GROUND ASSIGNMENTS -->

    <!-- NEW GROUND ASSIGNMENT DIALOG -->
    <r-modal
      v-if="showNewGroundAssignmentDialog"
      @close="showNewGroundAssignmentDialog = false"
    >
      <div v-if="showNewGroundAssignmentDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl">NEW ASSIGNMENT</div>
          <button
            @click="showNewGroundAssignmentDialog = false"
            class="btn-icon"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="text-2xl">
          {{ `${employee.surname}, ${employee.givenName}` }}
        </div>
        <div class="text-xl text-blue-600">
          {{ `(${employee.companyName} - ${employee.companyId.number})` }}
        </div>

        <div class="my-2">
          <!-- GROUND ASSIGNMENT TYPE-->

          <div class="flex">
            <select
              class="block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedGroundAssignmentType"
              :items="groundAssignmentTypes"
            >
              <option v-for="option in groundAssignmentTypes" :key="option">
                {{ option }}
              </option>
            </select>
            <div
              class="ml-3 flex items-center"
              v-if="
                selectedGroundAssignmentType === 'Travel' ||
                selectedGroundAssignmentType === 'Layover'
              "
            >
              <Switch
                v-model="groundAssignmentConfirmed"
                class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
              >
                <span class="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  class="pointer-events-none absolute h-full w-full rounded-md bg-white"
                />
                <span
                  aria-hidden="true"
                  :class="[
                    groundAssignmentConfirmed ? 'bg-green-600' : 'bg-gray-200',
                    'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                  ]"
                />
                <span
                  aria-hidden="true"
                  :class="[
                    groundAssignmentConfirmed
                      ? 'translate-x-5'
                      : 'translate-x-0',
                    'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                  ]"
                />
              </Switch>

              <div class="ml-3">
                {{ groundAssignmentConfirmed ? "Confirmed" : "Not Confirmed" }}
              </div>
            </div>
          </div>

          <!-- GROUND ASSIGNMENT  LOCATIONS -->

          <div class="flex my-2">
            <div class="max-width-200px" :class="{ hidden: !showOriginInput }">
              <p class="ml-2 text-sm text-gray-500">From</p>

              <input
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                v-model="groundAssignmentOriginIata"
                type="text"
                maxlength="3"
                name="originIata"
                id="originIata"
                placeholder="IATA"
              />
            </div>

            <div
              class="max-width-200px"
              :class="{ hidden: !showDestinationInput }"
            >
              <p class="ml-2 text-sm text-gray-500">To</p>
              <input
                v-model="groundAssignmentDestinationIata"
                type="text"
                maxlength="3"
                name="destinationIata"
                id="destinationIata"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                placeholder="IATA"
              />
            </div>
          </div>

          <!-- START DATE AND TIME -->
          <div class="flex mb-2">
            <div>
              <p class="ml-2 text-sm text-gray-500">Start</p>
              <div
                class="flex items-center my-2"
                v-if="selectedGroundAssignmentType"
              >
                <input
                  class="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  v-model="groundAssignmentStartDate"
                  type="date"
                />

                <div :class="{ hidden: !showTimes }">
                  <input
                    class="ml-1 block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    v-model="groundAssignmentStartTime"
                    type="time"
                  />
                </div>
              </div>
            </div>

            <div class="mx-5"></div>

            <div>
              <p class="ml-2 text-sm text-gray-500">End</p>
              <div
                class="flex items-center my-2"
                v-if="selectedGroundAssignmentType"
              >
                <input
                  class="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  v-model="groundAssignmentEndDate"
                  type="date"
                />
                <div :class="{ hidden: !showTimes }">
                  <input
                    class="ml-1 block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    v-model="groundAssignmentEndTime"
                    type="time"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- ASSIGNMENT REMARKS -->
          <div class="text-small text-gray-500">Remarks</div>

          <div class="w-full">
            <div class="mb-2">
              <textarea
                v-model="remarks"
                rows="4"
                name="remarks"
                id="remarks"
                class="block w-full rounded-md border-0 py-1.5 text-base text-blue-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-2">
          <div>
            <div>
              <button
                @click="handleSaveGroundAssignment"
                class="btn bg-blue-500 text-white"
              >
                Assign
              </button>
            </div>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- GROUND ASSIGNMENT INFORMATION DIALOG -->
    <r-modal
      v-if="showGroundAssignmentInformationDialog"
      @close="showGroundAssignmentInformationDialog = false"
    >
      <ground-assignment-information
        v-if="groundAssignment"
        :groundAssignment="groundAssignment"
        @close="handleCloseGroundAssignmentInformationDialog($event)"
        @update="handleUpdateGroundAssignment($event)"
      >
      </ground-assignment-information>
    </r-modal>
    <!-- #endregion -->

    <!-- #region BOARD -->
    <div class="board" ref="board">
      <!-- LEFT PANEL -->
      <div v-if="boardStyle === 'Board' && page" class="left-panel">
        <div class="left-panel-header bg-gray-500-l2">
          <div class="text-base flex justify-center items-center w-[90px]">
            Employees
          </div>
        </div>
        <div
          class="left-panel-row bg-gray-500-l4 cursor-pointer"
          @click="handleShowEmployeeInformationDialog(item.employee)"
          v-for="item in page.items"
          :key="item.employee._id"
          :style="{ height: `${rowHeight}px` }"
        >
          <div class="text-base text-align text-bold text-truncate">
            {{
              `${item.employee.surname}, ${item.employee.givenName.substring(
                0,
                1
              )}`
            }}
          </div>
          <div class="text-[10px] text-truncate">
            {{ `${item.employee.companyName} - ${item.employee.jobTitle}` }}
          </div>
          <div class="text-xs text-truncate">
            {{
              `${item.employee.gatewayCode} - ${item.employee.companyId.number}`
            }}
          </div>
        </div>
      </div>
      <!-- RIGHT PANEL -->
      <div
        v-if="boardStyle === 'Board'"
        class="right-panel-container"
        ref="scrollContainer"
      >
        <div v-if="page" class="right-panel">
          <!-- Flight Tooltip -->
          <div
            class="flight-tooltip text-[12px]"
            :style="tooltipStyle"
            v-if="tooltip"
          >
            <div class="border-b border-gray-500 pb-1 mb-1 text-base">
              {{ tooltip.customerName }}
            </div>

            <div>
              {{
                `${tooltip.flightNumber} . ${tooltip.aircraftRegistration} . ${tooltip.originIata} - ${tooltip.destinationIata} `
              }}
            </div>

            <div class="border-b border-gray-500 pb-1 mb-1">
              {{
                `${formatDateTime(tooltip.startTime)} . ${formatTime(
                  tooltip.endTime
                )}`
              }}
            </div>

            <div
              v-if="
                tooltip.flightCrewmembers &&
                tooltip.flightCrewmembers.length > 0
              "
            >
              <div
                class="flex"
                v-for="crewmember in tooltip.flightCrewmembers"
                :key="crewmember._id"
              >
                <div>{{ crewmember.role }}:</div>
                <div v-if="crewmember.employee" class="ml-1">
                  {{ crewmember.employee.surname }},
                  {{ crewmember.employee.givenName }}
                </div>
                <div v-else>
                  {{ crewmember.assignedToCompany }}
                </div>
              </div>
            </div>
            <div v-else>No flight crewmembers assigned.</div>

            <!-- <div class="flex">
              <div>{{ tooltip.role }}:</div>
              <div class="ml-1">
                {{ tooltip.employee.surname }},
                {{ tooltip.employee.givenName }}
              </div>
            </div> -->
          </div>
          <!-- Header -->
          <div
            class="right-panel-header bg-gray-500-l4 cursor-pointer"
            @click="handleOpenDateFilter(item)"
            v-for="(item, index) in dateFilter.days"
            :key="index"
            :style="{
              top: 0,
              left: `${(1440 / scale) * index}px`,
              width: `${dayWidth}px`,
            }"
          >
            {{ formatHeaderDate(item) }}
          </div>

          <!-- Day Lines -->
          <div
            class="day-line"
            v-for="(day, index) in dateFilter.days"
            :key="day"
            :style="{
              left: `${(1440 / scale) * index}px`,
              height: `${availableBoardHeight + 50}px`,
            }"
          ></div>

          <!-- Hour Boxes -->
          <div
            class="hour-box text-[10px]"
            :class="{ hidden: marker.value === '00' || scale > 4 }"
            v-for="(marker, index) in dateFilter.hourBoxes"
            :key="marker.key"
            :style="{ left: `${(60 / scale) * index}px` }"
          >
            {{ marker.value }}
          </div>

          <!-- Hour Lines -->
          <div
            class="hour-line"
            :class="{ hidden: marker.value === '00' || scale > 6 }"
            v-for="(marker, index) in dateFilter.hourLines"
            :key="marker.key"
            :style="{
              left: `${(60 / scale) * index}px`,
              height: `${availableBoardHeight + 7}px`,
            }"
          ></div>

          <!-- Now Box -->
          <div
            class="now-box"
            v-if="showCurrentTime"
            :style="{
              left: `${nowLineLeft}px`,
            }"
          >
            {{ formatTime(new Date()) }}
          </div>

          <!-- Now Line -->
          <div
            class="now-line"
            v-if="showCurrentTime"
            :style="{
              left: `${nowLineLeft}px`,
              height: `${availableBoardHeight + 7}px`,
            }"
          ></div>

          <!-- Employees -->
          <div
            class="right-panel-row"
            @click="handleStartNewGroundAssignment(item, $event)"
            v-for="(item, index) in page.items"
            :key="item.employee._id"
            :style="{
              top: `${index * rowHeight + 50}px`,
              width: `${boardWidth}px`,
              height: `${rowHeight}px`,
            }"
          >
            <!-- Bid Days -->
            <div
              v-for="bidday in item.employee.biddays"
              :key="`${item.employee._id}-${bidday.year}-${bidday.month}-${bidday.day}`"
              class="bidday-panel"
              :style="getBidDayStyle(bidday)"
            >
              {{ bidday.description }}
            </div>

            <!-- Assignment Shadow  -->
            <div
              v-if="assignmentData"
              class="assignment-shadow"
              @click="handleAssignmentShadowClicked(item, $event)"
              :style="computedAssignmentShadow"
            >
              <div>
                {{ shadowText }}
              </div>
            </div>
            <!--Assignments-->
            <div v-for="assignment in item.assignments" :key="assignment._id">
              <!-- Flight Assignment -->
              <div
                v-if="assignment.type === 'Flight'"
                @click="
                  handleShowFlightAssignmentInformationDialog(
                    assignment,
                    $event
                  )
                "
                @mouseover="handleShowFlightTooltip(assignment, index)"
                @mouseleave="handleHideFlightTooltip"
                class="board-label-frame"
                :style="{
                  width: `${assignment.duration / scale}px`,
                  left: `${getLocationLeft(
                    assignment.startTime,
                    dateFilter.days[0],
                    scale
                  )}px`,
                }"
              >
                <FlightAssignmentLabel :assignment="assignment" />
              </div>

              <!-- Station Assignment -->
              <div
                v-else-if="assignment.type === 'Station Assignment'"
                @click="
                  handleShowStationAssignmentInformationDialog(
                    assignment,
                    $event
                  )
                "
                class="board-label-frame"
                :style="{
                  width: `${assignment.duration / scale}px`,
                  left: `${getLocationLeft(
                    assignment.startTime,
                    dateFilter.days[0],
                    scale
                  )}px`,
                }"
              >
                <StationAssignmentLabel :assignment="assignment" />
              </div>

              <!-- Ground Assignment -->
              <div
                v-else
                @click="
                  handleShowGroundAssignmentInformationDialog(
                    item,
                    assignment,
                    $event
                  )
                "
                class="board-label-frame"
                v-bind:style="{
                  width: `${assignment.duration / scale}px`,
                  left: `${getLocationLeft(
                    assignment.startTime,
                    dateFilter.days[0],
                    scale
                  )}px`,
                }"
              >
                <GroundAssignmentLabel :groundAssignment="assignment" />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="right-panel">
          <div v-if="loading" class="ma-5 text-red-500 text-2xl">
            Loading ...
          </div>
          <div v-else class="ma-5 text-red-500 text-2xl">
            No employees found with the selected criteria
          </div>
        </div>
      </div>

      <!-- LIST -->
      <div v-if="boardStyle === 'List' && page">
        <div class="flex flex-col justify-between">
          <!-- Data Table -->
          <div class="relative overflow-x-auto">
            <table
              class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
            >
              <thead
                class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
              >
                <tr>
                  <th scope="col" class="px-6 py-3">Select</th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    @click="sortArrayByKey('employee.surname')"
                  >
                    Last Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    @click="sortArrayByKey('employee.givenName')"
                  >
                    First Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    @click="sortArrayByKey('employee.jobTitle')"
                  >
                    Job Title
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    @click="sortArrayByKey('employee.company')"
                  >
                    Company
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    @click="sortArrayByKey('from')"
                  >
                    FROM
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    @click="sortArrayByKey('to')"
                  >
                    TO
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    @click="sortArrayByKey('start')"
                  >
                    Start
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    @click="sortArrayByKey('end')"
                  >
                    End
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    @click="sortArrayByKey('type')"
                  >
                    Assignment Type
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    @click="sortArrayByKey('assignedBy')"
                  >
                    Assigned By
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="assignment in sortedAssignments"
                  :key="assignment._id"
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td class="w-4 p-4">
                    <div class="flex items-center">
                      <input
                        v-model="selectedAssignments"
                        :id="assignment.id"
                        :value="assignment"
                        type="checkbox"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </div>
                  </td>
                  <td
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {{ assignment.employee.surname }}
                  </td>
                  <td>{{ assignment.employee.givenName }}</td>
                  <td class="px-6 py-2">
                    {{ assignment.employee.jobTitle }}
                  </td>
                  <td class="px-6 py-2">
                    {{ assignment.employee.companyName }}
                  </td>
                  <td class="px-6 py-2">
                    {{ assignment.originIata }}
                  </td>
                  <td class="px-6 py-2">
                    {{ assignment.destinationIata }}
                  </td>
                  <td class="px-6 py-2">
                    {{ formatDateTime(assignment.startTime) }}
                  </td>
                  <td class="px-6 py-2">
                    {{ formatDateTime(assignment.endTime) }}
                  </td>
                  <td class="px-6 py-2">{{ assignment.type }}</td>
                  <td class="px-6 py-2">{{ assignment.assignedBy }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="flex justify-between my-3 p-2">
            <div class="flex justify-between">
              <div
                @click="selectedAssignments = [...sortedAssignments]"
                class="my-1 ml-1 cursor-pointer text-base underline text-blue-600"
              >
                Select All
              </div>

              <div
                v-if="selectedAssignments.length"
                @click="selectedAssignments = []"
                class="my-1 ml-3 cursor-pointer text-base underline text-red-600"
              >
                Clear Selected
              </div>
            </div>

            <button
              v-if="selectedAssignments.length"
              @click="handleDeleteAssignments"
              class="btn bg-red-500 text-white"
            >
              Remove Selected
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- #endregion -->
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import FlightAssignmentLabel from "../components/FlightAssignmentLabel.vue";
import StationAssignmentLabel from "../components/StationAssignmentLabel.vue";
import GroundAssignmentLabel from "../components/GroundAssignmentLabel.vue";
import SpecialAirports from "../components/SpecialAirports.vue";
import FlightAssignmentInformation from "../components/FlightAssignmentInformation.vue";
import GroundAssignmentInformation from "../components/GroundAssignmentInformation.vue";
import StationAssignmentInformation from "../components/StationAssignmentInformation.vue";
import EmployeeInformation from "../components/EmployeeInformation.vue";
import AssignmentWarningsModal from "../components/AssignmentWarningsModal.vue";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  Switch,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "assignmentBoard",
  mixins: [mixin],
  components: {
    FlightAssignmentInformation,
    GroundAssignmentInformation,
    StationAssignmentInformation,
    EmployeeInformation,
    FlightAssignmentLabel,
    StationAssignmentLabel,
    GroundAssignmentLabel,
    SpecialAirports,
    AssignmentWarningsModal,
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
    Switch,
  },
  data() {
    return {
      loading: false,

      inactivityTimer: null,

      assignment: null,

      tooltip: null,
      tooltipStyle: {
        display: "none",
      },

      sortedAssignments: [],

      //#region-----------------------------------Paginate
      availableBoardHeight: 0,
      employeesSchedule: [],
      filteredEmployeesSchedule: [],
      itemsPerPage: 0,
      printGroundCrewmembers: true,
      pages: [],
      page: null,
      // page: { index: 0, items: [] },
      rowHeight: 60,
      disableNextPageButton: true,
      disablePreviousPageButton: true,
      //#endregion

      //----------------------------------------Board
      boardStyle: "Board",
      scale: 4,

      // assignmentsListDialog: false,
      originIata: "", //Used to sort station assignment flights
      paddingBefore: 30,
      paddingAfter: 30,

      filterByStation: {
        active: false,
        startTime: new Date(),
        originIata: "",
      },

      //------------------------------------------Employee
      employeeDialog: false,
      showEmployeeInformationDialog: false,
      employee: null,
      workMonth: null,

      //------------------------------------------Filters and Settings
      showFiltersDialog: false,

      iataMatchOptions: ["Departing or Arriving", "Departing", "Arriving"],

      filterByAirport: false,
      filterByCompany: false,
      selectedIataCode: null,
      selectedIataMatch: "Departing or Arriving",

      filterByAircraftDeck: false,
      selectedAircraftDeck: null,
      aircraftDeckOptions: ["Cargo Aircraft", "Passenger Aircraft"],

      filterByCompany: false,
      selectedCompany: null,

      search: "",

      showDateFilterDialog: false,
      startDate: null,
      numberOfDays: 7,
      numberOfDaysOptions: [3, 5, 7, 14, 21, 31],

      filterByJobTitle: false,
      selectedJobTitles: [],

      //-------------------------------Flight Assignments
      flightAssignment: null,
      showNewFlightAssignmentDialog: false,
      showFlightAssignmentInformationDialog: false,

      selectedAssignments: [],

      remarks: "",
      selectedRole: null,

      //-------------------------------Trip Assignments

      showTripAssignmentInformationDialog: false,
      showNewTripAssignmentDialog: false,
      tripRoles: null,

      //-------------------------------Station Assignments
      stationAssignment: null,
      showNewStationAssignmentDialog: false,
      showStationAssignmentInformationDialog: false,
      startDate: null,
      startTime: null,

      //-------------------------------Assignments (Shared)
      assignmentWarnings: [],

      // -----------------------------------------Ground Assignments
      showGroundAssignmentInformationDialog: false,
      groundAssignment: null,
      groundAssignmentMinimumDuration: 30,
      showGroundAssignmentStartCalendar: false,
      showGroundAssignmentEndCalendar: false,
      selectedGroundAssignmentType: "",
      groundAssignmentConfirmed: false,
      groundAssignmentOrigin: null,
      groundAssignmentDestination: null,
      groundAssignmentStartDate: new Date().toISOString().substring(0, 10),
      groundAssignmentStartTime: "00:00",
      groundAssignmentEndDate: new Date().toISOString().substring(0, 10),
      groundAssignmentEndTime: "23:59",
      groundAssignmentStartDateTime: new Date(),
      groundAssignmentEndDateTime: new Date(),
      groundAssignmentDuration: 0,
      groundAssignmentOriginIata: "",
      groundAssignmentDestinationIata: "",
      groundAssignmentTypeRules: [(v) => !!v || "Assignment Type is required"],

      showNewGroundAssignmentDialog: false,
      groundAssignmentStart: "",
      showStartCalendar: false,
      showEndCalendar: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      //-------------------------------General Warnings
      filteredWarnings: [],
      showAssigmentWarningsModal: false,
      showWarningsDialog: false,

      //-----------------------------Special Airports
      specialAirportsDialog: false,
    };
  },

  created() {
    (async () => {
      this.getData();
    })();
  },
  mounted() {
    this.$nextTick(() => {
      const board = this.$refs.board;

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );

      if (this.assignmentData) {
        switch (this.assignmentData.type) {
          case "Trip Assignment":
            this.tripRoles = new Map();

            this.assignmentData.trip.flights.forEach((flight) => {
              this.tripRoles.set(flight._id, null);
            });

            break;

          case "Station Assignment":
            this.startDate = new Date(
              this.assignmentData.stationAssignment.startTime
            )
              .toISOString()
              .substring(0, 10);
            this.startTime = new Date(
              this.assignmentData.stationAssignment.startTime
            )
              .toISOString()
              .substring(11, 16);

            this.endDate = new Date(
              this.assignmentData.stationAssignment.endTime
            )
              .toISOString()
              .substring(0, 10);
            this.endTime = new Date(
              this.assignmentData.stationAssignment.endTime
            )
              .toISOString()
              .substring(11, 16);

            break;

          default:
            break;
        }
      }
    });

    window.addEventListener("resize", this.onResize, { passive: true });

    this.startInactivityTimer();
    document.addEventListener("click", this.resetInactivityTimer);
  },

  unmounted() {
    this.clearInactivityTimer();
    document.removeEventListener("click", this.resetInactivityTimer);
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    hardWarnings() {
      return this.assignmentWarnings.filter((item) => {
        return item.level === "Hard";
      });
    },

    canOverrideWarnings() {
      if (
        this.assignmentData.type === "Flight Assignment" ||
        this.assignmentData.type === "Trip Assignment"
      ) {
        return this.canOverrideFlightAssignmentWarnings;
      } else {
        return this.canOverrideGroundAssignmentWarnings;
      }
    },

    assignmentData() {
      return this.$store.getters.assignmentData;
    },

    shadowText() {
      switch (this.assignmentData.type) {
        case "Flight Assignment":
          return `${this.assignmentData.flight.aircraft.registration} . ${this.assignmentData.flight.flightNumber} . ${this.assignmentData.flight.originIata} - ${this.assignmentData.flight.destinationIata}`;
        case "Trip Assignment":
          return `Trip ${this.assignmentData.trip.route}`;
        default:
          return `Station Assignment: ${this.assignmentData.stationAssignment.originIata}`;
      }
    },

    aircraftDeckFilter() {
      return this.$store.getters.aircraftDeckFilter;
    },

    assignmentsAirportFilter() {
      return this.$store.getters.assignmentsAirportFilter;
    },

    companyFilter() {
      return this.$store.getters.companyFilter;
    },

    jobTitlesFilter() {
      return this.$store.getters.jobTitlesFilter;
    },

    warnings() {
      let warnings = [];

      if (this.filteredEmployeesSchedule) {
        let priorAssignment;
        let assignment;
        let timeOut;
        let timeIn;
        let timeNow = new Date().getTime();
        let ts = 0;

        this.filteredEmployeesSchedule.forEach((item) => {
          if (item.assignments.length) {
            const _sortedAssignments = item.assignments.sort(
              this.compareAssignments
            );

            // Check Overlap and Discontinuity
            priorAssignment = item.assignments[0];

            for (let i = 0; i < _sortedAssignments.length; i++) {
              assignment = item.assignments[i];

              if (i > 0) {
                //Check discontinuity starting on the second assignment

                timeOut = new Date(assignment.startTime).getTime();
                timeIn = new Date(priorAssignment.endTime).getTime();

                if (assignment.originIata !== priorAssignment.destinationIata) {
                  warnings.push({
                    assignment,
                    level: "Soft",
                    type: "Route Discontinuity",
                    employeeName: this.formatName(
                      item.employee.givenName,
                      item.employee.surname,
                      "l"
                    ),
                    message: `Assignment starts in ${
                      assignment.originIata
                    } on ${this.formatDateTime(
                      assignment.startTime
                    )}, prior assignment ended in ${
                      priorAssignment.destinationIata
                    } on ${this.formatDateTime(priorAssignment.endTime)}`,
                  });
                }

                if (timeOut < timeIn) {
                  warnings.push({
                    assignment,
                    level: "Soft",
                    type: "Time Overlap",
                    employeeName: this.formatName(
                      item.employee.givenName,
                      item.employee.surname,
                      "l"
                    ),
                    message: `Assignment starts in ${
                      assignment.originIata
                    } on ${this.formatDateTime(
                      assignment.startTime
                    )}, prior assignment ended in ${
                      priorAssignment.destinationIata
                    } on ${this.formatDateTime(priorAssignment.endTime)}`,
                  });
                }

                priorAssignment = { ...assignment };
              }

              // Check Travel/Layover Confirmation

              if (
                (assignment.type === "Travel" ||
                  assignment.type === "Layover") &&
                !assignment.confirmed
              ) {
                timeOut = new Date(assignment.startTime).getTime();
                ts = (timeOut - timeNow) / (1000 * 60 * 60);

                //Add warning if unconfirmed in the nexr 72 hours
                if (ts > 0 && ts < 72) {
                  warnings.push({
                    assignment: { ...assignment },
                    level: "Soft",
                    type: `Unconfirmed ${assignment.type}`,
                    employeeName: this.formatName(
                      item.employee.givenName,
                      item.employee.surname,
                      "l"
                    ),
                    message: `Unconfirmed ${
                      assignment.type
                    } on ${this.formatDateTime(assignment.startTime)}`,
                  });
                }
              }
            }
          }
        });
      }

      return warnings;
    },

    pageNumber() {
      return this.$store.getters.assignmentBoardPageNumber;
    },

    showOriginInput() {
      switch (this.selectedGroundAssignmentType) {
        case "Special Assignment":
        case "Layover":
        case "Training - Ground School":
        case "Training - SIM":
        case "Do Not Use":
        case "Sick":
        case "Quarantine":
        case "Quarantine - Day OFF":
        case "Travel":
          return true;

        default:
          // case "Vacation":
          // case "Holiday":
          // case "LOA - Jury Duty":
          // case "LOA - Long Term Disability":
          // case "LOA - Short Term Disability":
          // case "LOA - Workers' Compensation":
          // case "Floating Holiday":
          // case "Leave - Bereavement":
          // case "Leave - FMLA":
          // case "Leave - Medical":
          // case "Leave - Military":
          // case "Leave - Non-FMLA":
          // case "Leave - Parental":
          // case "Leave - Personal":
          // case "Stand By":

          return false;
      }
    },

    showDestinationInput() {
      return this.selectedGroundAssignmentType === "Travel";
    },

    showCurrentTime() {
      const now = new Date().getTime();

      return (
        now > new Date(this.dateFilter.start).getTime() &&
        now < new Date(this.dateFilter.end).getTime()
      );
    },

    nowLineLeft() {
      const x = this.getLocationLeft(
        new Date().toISOString(),
        this.dateFilter.start,
        this.scale
      );

      if (x > 0 || x < this.boardWidth) {
        return x;
      } else {
        return 0;
      }
    },

    boardWidth() {
      return this.dateFilter.days.length * (1440 / this.scale) + 100;
    },

    dayWidth() {
      return 1440 / this.scale;
    },

    authorizedFlightRoles() {
      return this.$store.getters.authorizedFlightRoles;
    },
    companyOptions() {
      if (this.user) {
        return [...this.user.securityGroup.companies];
      } else {
        return [];
      }
    },

    jobTitles() {
      if (this.user) {
        return [...this.user.securityGroup.jobTitles];
      } else {
        return [];
      }
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },

    airports() {
      return this.$store.getters.airports;
    },

    formatReservationConfirmed() {
      return this.groundAssignmentConfirmed ? "Confirmed" : "Not Confirmed";
    },

    showTimes() {
      // switch (this.selectedGroundAssignmentType) {
      //   case "Do Not Use":
      //   case "Floating Holiday":
      //   case  "Holiday":
      //   case "Layover":
      //   case "Leave - Bereavement":
      //   case "Leave - FMLA":
      //   case "Leave - Medical":
      //   case "Leave - Military":
      //   case "Leave - Non-FMLA":
      //   case "Leave - Parental":
      //   case "Leave - Personal":
      //   case "LOA - Jury Duty":
      //   case "LOA - Long Term Disability":
      //   case "LOA - Short Term Disability":
      //   case "LOA - Workers' Compensatio":
      //   case "Quarantine - Day OFF":
      //   case "Quarantine":
      //   case "Sick":
      //   case "Special Assignment":
      //   case "Stand By":
      //   case "Training - Ground School":
      //   case "Training - SIM":
      //   case "Travel":
      //   case "Vacation":
      //     return true;

      //   default:
      //     return false;
      // }

      switch (this.selectedGroundAssignmentType) {
        case "Do Not Use":
        case "Layover":
        case "Special Assignment":
        case "Training - Ground School":
        case "Training - SIM":
        case "Travel":
        case "Sick":
          return true;

        default:
          return false;
      }
    },

    showStation() {
      switch (this.selectedGroundAssignmentType) {
        case "Do Not Use":
        case "Layover":
        case "Quarantine - Day OFF":
        case "Quarantine":
        case "Sick":
        case "Special Assignment":
        case "Training - Ground School":
        case "Training - SIM":
        case "Travel":
          return true;

        default:
          return false;
      }
    },

    computedAssignmentShadow() {
      if (this.assignmentData) {
        let minutes;
        let width;

        switch (this.assignmentData.type) {
          case "Flight Assignment":
            minutes =
              (new Date(this.assignmentData.flight.effectiveTimeOut).getTime() -
                new Date(this.dateFilter.days[0]).getTime()) /
              1000 /
              60;

            width =
              (this.paddingBefore +
                this.assignmentData.flight.effectiveBlockTime +
                this.paddingAfter) /
              this.scale;

            break;

          case "Trip Assignment":
            minutes =
              (new Date(this.assignmentData.trip.startTime) -
                new Date(this.dateFilter.days[0]).getTime()) /
              1000 /
              60;

            width =
              (this.paddingBefore +
                this.assignmentData.trip.duration +
                this.paddingAfter) /
              this.scale;

            break;

          default:
            minutes =
              (new Date(this.assignmentData.stationAssignment.startTime) -
                new Date(this.dateFilter.days[0]).getTime()) /
              1000 /
              60;

            width =
              (this.paddingBefore +
                this.assignmentData.stationAssignment.duration +
                this.paddingAfter) /
              this.scale;
            break;
        }

        const left = (minutes - this.paddingBefore) / this.scale;

        let style = {
          width: `${width}px`,
          left: `${left}px`,
          height: "60px",
          borderLeft: "1px dashed blue",
          borderRight: "1px dashed blue",
          backgroundColor: "#a8a8f5b2",
          visibility: "visible",
          opacity: 0.5,
        };

        return style;
      } else {
        return {
          width: "1px",
          left: "1px",
          height: "1px",
          visibility: "hidden",
        };
      }
    },

    user() {
      return this.$store.getters.user;
    },

    companyAssignmentToAssign() {
      return this.$store.getters.companyAssignmentToAssign;
    },
  },
  methods: {
    startInactivityTimer() {
      this.clearInactivityTimer();
      this.inactivityTimer = setTimeout(() => {
        this.handleLogout();
      }, this.timeoutDuration);
    },

    clearInactivityTimer() {
      if (this.inactivityTimer) {
        clearTimeout(this.inactivityTimer);
      }
    },

    resetInactivityTimer() {
      this.startInactivityTimer();
    },

    async handleLogout() {
      clearTimeout(this.inactivityTimer);
      aad.logout();
    },

    async getData() {
      this.loading = true;
      this.employeesSchedule = [];
      this.filteredEmployeesSchedule = [];
      this.pages = [];
      this.page = null;

      try {
        //--------------------------Aircraft Deck Filter
        let aircraftDeck = "Cargo and Pax.";

        if (this.aircraftDeckFilter.active) {
          aircraftDeck = this.aircraftDeckFilter.deck.slice();
        }

        //--------------------------Company Filter
        let companyFilter = {
          active: false,
          name: "",
        };

        if (this.companyFilter.active) {
          companyFilter = {
            active: true,
            name: this.companyFilter.name.slice(),
          };
        }

        //--------------------------Job Title Filter
        let jobTitlesFilter = {
          active: false,
          jobTitles: [],
        };

        if (this.jobTitlesFilter.active) {
          jobTitlesFilter = {
            active: true,
            jobTitles: [...this.jobTitlesFilter.jobTitles],
          };
        }

        const token = await aad.getIdTokenSilent();
        const res = await api.post(
          "/assignments/assignments",
          {
            dateFilterStart: this.dateFilter.start,
            dateFilterEnd: this.dateFilter.end,
            companyFilter,
            jobTitlesFilter,
            aircraftDeck,
          },
          {
            headers: {
              Authorization: "Bearer " + token.idToken,
            },
          }
        );

        if (res.status !== 200) {
          this.loading = false;
          this.snackbarColor = "red";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          this.$store.commit("updateAssignmentBoardPageNumber", 0);
          return;
        }

        let employees = res.data.employees;
        const assignments = res.data.assignments;
        const biddays = res.data.biddays;

        let items = [];
        let employeeAssignments = [];

        employees.forEach((employee) => {
          employee.biddays = biddays.filter((bidday) => {
            return bidday.employee_id === employee._id;
          });

          employeeAssignments = assignments.filter((assignment) => {
            return assignment.employee?._id === employee._id;
          });

          if (!this.assignmentsAirportFilter.active) {
            items.push({
              employee,
              assignments: employeeAssignments,
            });
          } else {
            //Note: If filter by station is active
            // show only employees with assignments
            if (employeeAssignments.length) {
              let bingo = 0;

              switch (this.assignmentsAirportFilter.active.match) {
                case "Departing":
                  bingo = employeeAssignments.filter((a) => {
                    return (
                      a.originIata === this.assignmentsAirportFilter.iataCode
                    );
                  });

                  break;
                case "Arriving":
                  bingo = employeeAssignments.filter((a) => {
                    return (
                      a.destinationIata ===
                      this.assignmentsAirportFilter.iataCode
                    );
                  });

                  break;

                default:
                  bingo = employeeAssignments.filter((a) => {
                    return (
                      a.originIata === this.assignmentsAirportFilter.iataCode ||
                      a.destinationIata ===
                        this.assignmentsAirportFilter.iataCode
                    );
                  });

                  break;
              }

              if (bingo.length) {
                items.push({
                  employee,
                  assignments: employeeAssignments,
                });
              }
            }
          }
        });

        this.employeesSchedule = items;

        this.filterEmployeesSchedule();

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    getFilterByStationStyle() {
      let style = {
        color: "white",
      };

      if (this.filterByStation.active) {
        style.color = "#eab208";
      }

      return style;
    },

    filterEmployeesSchedule() {
      let filterOne = [];
      let filterTwo = [];

      if (this.search.length) {
        filterOne = this.employeesSchedule.filter((item) => {
          return (
            item.employee.surname
              .toUpperCase()
              .startsWith(this.search.toUpperCase()) ||
            item.employee.givenName
              .toUpperCase()
              .startsWith(this.search.toUpperCase())
          );
        });
      } else {
        filterOne = [...this.employeesSchedule];
      }

      //-----------------------Filter by Station

      if (this.filterByStation.active) {
        let count;
        let previousAssignments;
        let lastAssignment;

        filterTwo = filterOne.filter((employee) => {
          count = 0;

          if (employee.assignments.length) {
            previousAssignments = employee.assignments.filter((a) => {
              return (
                new Date(a.endTime).getTime() <= this.filterByStation.startTime
              );
            });

            previousAssignments.sort((a, b) =>
              a.endTime < b.endTime ? -1 : 1
            );

            if (previousAssignments.length) {
              lastAssignment =
                previousAssignments[previousAssignments.length - 1];
              if (
                lastAssignment.destinationIata ===
                this.filterByStation.originIata
              ) {
                count++;
              }
            }
          }
          return count > 0;
        });
      } else {
        filterTwo = [...filterOne];
      }

      //----------------------Get Sorted Assignments
      this.sortedAassignments = [];

      if (filterTwo.length) {
        filterTwo.forEach((item) => {
          if (item.assignments.length) {
            item.assignments.forEach((assignment) => {
              this.sortedAssignments.push(assignment);
            });
          }
        });
      }

      this.filteredEmployeesSchedule = [...filterTwo];

      this.paginate();
    },

    paginate() {
      this.pages = [];
      let _pages = [];
      this.page = null;

      if (this.filteredEmployeesSchedule.length) {
        let chunk = Math.min(
          Math.floor(this.availableBoardHeight / this.rowHeight),
          this.filteredEmployeesSchedule.length
        );

        //Show warning if not enough space to display
        if (chunk < 1) {
          this.snackbarColor = "red";
          this.snackbarText = "Not enough screen space to display data";
          this.snackbarVisible = true;
          return;
        }

        //Split data into pages
        for (let i = 0; i < this.filteredEmployeesSchedule.length; i += chunk) {
          let tempArray;
          tempArray = this.filteredEmployeesSchedule.slice(i, i + chunk);
          _pages.push({
            index: _pages.length,
            items: tempArray,
          });
        }

        this.pages = [..._pages];

        const i = this.pages.findIndex((page) => {
          return page.index === this.pageNumber;
        });

        if (i < 0) {
          this.$store.commit("updateAssignmentBoardPageNumber", 0);
        }

        this.page = this.pages[this.pageNumber];
      }
    },

    handleToggleStationFilter() {
      if (this.filterByStation.active) {
        this.filterByStation = {
          active: false,
          startTime: new Date(),
          originIata: "",
        };
      } else {
        let startTime;
        let originIata;

        switch (this.assignmentData.type) {
          case "Flight Assignment":
            startTime = new Date(
              this.assignmentData.flight.effectiveTimeOut
            ).getTime();
            originIata = this.assignmentData.flight.originIata.slice();

            break;

          case "Trip Assignment":
            startTime = new Date(this.assignmentData.trip.startTime);
            originIata = this.assignmentData.trip.flights[0].originIata.slice();

            break;

          default:
            startTime = new Date(
              this.assignmentData.stationAssignment.startTime
            );
            originIata =
              this.assignmentData.stationAssignment.originIata.slice();

            break;
        }

        this.filterByStation = {
          active: true,
          startTime,
          originIata,
        };
      }

      this.filterEmployeesSchedule();
      this.paginate();
    },

    getTripFlightRole(flight_id) {
      const role = this.tripRoles.get(flight_id);

      if (role) {
        return role;
      } else {
        return "Select Role";
      }
    },

    handleSetTripRole(flight_id) {
      this.tripRoles.set(flight_id, this.selectedRole);
    },

    getWarningStyle(level) {
      let style = {
        "background-color": "orange",
      };

      if (level === "Hard") {
        style = {
          "background-color": "pink",
        };
      }

      return style;
    },

    sortWarningsByKey(key) {
      const sortedAsc = this.isSortedAsc(this.filteredWarnings, key);

      if (sortedAsc) {
        this.filteredWarnings.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.filteredWarnings.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }
    },

    sortArrayByKey(key) {
      const sortedAsc = this.isSortedAsc(this.sortedAssignments, key);

      if (sortedAsc) {
        this.sortedAssignments.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.sortedAssignments.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }
    },

    isSortedAsc(arr, key) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i][key] > arr[i + 1][key]) {
          return false;
        }
      }
      return true;
    },

    getRowStyle(assignment) {
      const i = this.selectedAssignments.findIndex((item) => {
        return item._id === assignment._id;
      });

      if (i >= 0) {
        return {
          "background-color": "green",
          color: "gray",
        };
      } else {
        return {
          "background-color": "white",
          color: "black",
        };
      }
    },

    handleShowWarnings() {
      if (this.warnings.length) {
        this.filteredWarnings = [...this.warnings];
        this.showWarningsDialog = true;
      } else {
        this.snackbarColor = "green";
        this.snackbarText = "No warnings found with the selected filters";
        this.snackbarVisible = true;
      }
    },

    onResize() {
      const board = this.$refs.board;

      if (!board) return;

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );

      this.paginate();
    },

    handlePageUp() {
      if (this.pageNumber > 0) {
        const val = this.pageNumber - 1;
        this.$store.commit("updateAssignmentBoardPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handlePageDown() {
      if (this.pageNumber < this.pages.length - 1) {
        const val = this.pageNumber + 1;
        this.$store.commit("updateAssignmentBoardPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },

    handleNavigateToHome() {
      this.$router.go({ name: "home" });
    },

    async handleCloseAssignmentWarningsModal(assign) {
      this.showAssigmentWarningsModal = false;

      if (assign) {
        this.loading = true;
        let res;

        switch (this.assignment.type) {
          case "Flight":
            if (this.assignmentData.crewmember) {
              res = await this.$store.dispatch(
                "coverFlightAssignment",
                this.assignment
              );
            } else {
              res = await this.$store.dispatch(
                "saveFlightAssignment",
                this.assignment
              );
            }

            break;

          case "Trip":
            res = await this.$store.dispatch("saveTripAssignment", {
              employee_id: this.employee._id,
              remarks: this.remarks,
              assignment: this.assignment,
            });

            break;

          case "Station Assignment":
            res = await this.$store.dispatch(
              "saveStationAssignment",
              this.assignment
            );
            break;

          default:
            if (this.assignment._id) {
              res = await this.$store.dispatch(
                "updateGroundAssignment",
                this.assignment
              );
            } else {
              res = await this.$store.dispatch(
                "saveGroundAssignment",
                this.assignment
              );
            }

            break;
        }

        this.loading = false;
        this.assignment = null;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.getData();
      }
    },

    handleOpenFilters() {
      if (!this.selectedCompany) {
        this.selectedCompany = this.user.companyName.slice();
      }

      if (!this.selectedAircraftDeck) {
        this.selectedAircraftDeck = "Cargo Aircraft";
      }

      //------------------------------------------Airports Filter
      if (this.assignmentsAirportFilter.active) {
        this.filterByAirport = true;
        this.selectedIataCode = this.assignmentsAirportFilter.iataCode.slice();
      }

      //-----------------------------------------Aircraft Deck Filter
      if (this.aircraftDeckFilter.active) {
        this.filterByAircraftDeck = true;
        this.selectedAircraftDeck = this.aircraftDeckFilter.deck.slice();
      }

      //----------------------------------------Company Name Filter
      if (this.companyFilter.active) {
        this.filterByCompany = true;
        this.selectedCompany = this.companyFilter.name.slice();
      }

      //----------------------------------------Job Title Filter
      if (this.jobTitlesFilter.active) {
        this.filterByJobTitle = true;
        this.selectedJobTitles = [...this.jobTitlesFilter.jobTitles];
      } else {
        this.filterByJobTitle = false;
        this.selectedJobTitles = [];
      }

      this.showFiltersDialog = true;
    },

    handleSaveFilters() {
      this.showFiltersDialog = false;

      //------------------------------------Aircraft Deck Filter
      let aircraftDeckFilter = {
        active: false,
        deck: "",
      };

      if (this.filterByAircraftDeck) {
        aircraftDeckFilter = {
          active: true,
          deck: this.selectedAircraftDeck.slice(),
        };
      }

      this.$store.commit("updateAircraftDeckFilter", aircraftDeckFilter);

      //------------------------------------Airport Filter
      let assignmentsAirportFilter = {
        active: false,
        match: "",
        iataCode: "",
      };

      if (this.filterByAirport) {
        if (this.selectedIataCode?.length === 3) {
          const i = this.airports.findIndex((item) => {
            return item.iata === this.selectedIataCode.toUpperCase();
          });

          if (i >= 0) {
            assignmentsAirportFilter = {
              active: true,
              match: this.selectedIataMatch.slice(),
              iataCode: this.selectedIataCode.slice().toUpperCase(),
            };
          } else {
            this.filterByAirport = false;
            this.selectedIataCode = null;
          }
        } else {
          this.filterByAirport = false;
        }
      }

      this.$store.commit(
        "updateAssignmentsAirportFilter",
        assignmentsAirportFilter
      );

      //-----------------------------Company Filter
      let companyFilter = {
        active: false,
        name: "",
      };

      if (this.filterByCompany && this.selectedCompany) {
        companyFilter = {
          active: true,
          name: this.selectedCompany.slice(),
        };
      }

      this.$store.commit("updateCompanyFilter", companyFilter);

      //------------------------------Job Titles Filter
      let jobTitlesFilter = {
        active: false,
        jobTitles: [],
      };

      if (this.filterByJobTitle && this.selectedJobTitles.length) {
        jobTitlesFilter = {
          active: true,
          jobTitles: [...this.selectedJobTitles],
        };
      }

      this.$store.commit("updateJobTitlesFilter", jobTitlesFilter);

      this.getData();
    },

    handleShowFlightTooltip(assignment, index) {
      this.tooltip = { ...assignment };

      let style = {
        display: "block",
        top: `${index * this.rowHeight + 50 + 45}px`,
        left: `${
          this.getLocationLeft(
            assignment.startTime,
            this.dateFilter.days[0],
            this.scale
          ) + 30
        }px`,
      };

      if (this.pages.length > 1 && index >= this.page.items.length - 2) {
        style.top = `${index * this.rowHeight - 50}px`;
      } else {
        style.top = `${index * this.rowHeight + 50 + 45}px`;
      }

      this.tooltipStyle = style;
    },

    handleHideFlightTooltip() {
      setTimeout(() => {
        this.tooltipStyle = {
          display: "none",
        };
      }, 150);
    },

    handleNavigateToEmployeeProfile() {
      this.$router.push({
        name: "employeeUpdate",
        params: { id: this.employee._id },
      });
    },

    zoomIn() {
      if (this.scale > 1) {
        this.scale -= 1;
      }
    },

    zoomOut() {
      if (this.scale < 8) {
        this.scale += 1;
      }
    },

    handleToggleBoardStyle() {
      if (this.boardStyle === "Board") {
        this.boardStyle = "List";
      } else {
        this.boardStyle = "Board";
      }
    },

    handleRemoveTarget() {
      this.$store.commit("updateAssignmentData", null);
    },

    //-------------------------------------Filters and Settings
    handleOpenDateFilter(item) {
      this.startDate = item.substring(0, 10);
      this.numberOfDays = this.dateFilter.days.length;

      this.showDateFilterDialog = true;
    },

    handleSaveDateFilter() {
      const filterStart = new Date(this.startDate).getTime();

      this.showDateFilterDialog = false;

      //Pass filterStart in miliseconds, numberOfDays as integer
      this.$store.commit("updateDateFilter", {
        filterStart,
        numberOfDays: this.numberOfDays,
      });

      this.getData();
    },

    //--------------------------Employee Information
    handleShowEmployeeInformationDialog(employee) {
      if (this.canReadEmployeeContactInformation) {
        this.employee = { ...employee };

        this.showEmployeeInformationDialog = true;
      }
    },

    handleAssignmentShadowClicked(item, e) {
      this.employee = { ...item.employee, assignments: [...item.assignments] };

      e.stopPropagation();
      this.selectedRole = this.getSelectedRole();

      if (this.assignmentData.type === "Flight Assignment") {
        if (this.assignmentData.crewmember) {
          this.remarks = this.assignmentData.crewmember.remarks
            ? this.assignmentData.crewmember.remarks.slice()
            : null;
        }

        this.showNewFlightAssignmentDialog = true;
      } else if (this.assignmentData.type === "Trip Assignment") {
        this.showNewTripAssignmentDialog = true;
      } else if (this.assignmentData.type === "Station Assignment") {
        this.showNewStationAssignmentDialog = true;
      }
    },

    //--------------------------------------Flight Assignments
    getSelectedRole() {
      let i;
      let role;

      i = this.authorizedFlightRoles.findIndex((item) => {
        return item === this.employee.jobTitle;
      });

      if (i < 0) {
        i = this.authorizedFlightRoles.findIndex((item) => {
          return item === "Jumpseat";
        });
      }

      if (i >= 0) {
        role = this.authorizedFlightRoles[i].slice();
      } else {
        role = this.authorizedFlightRoles[0].slice();
      }

      return role;
    },

    async handleSaveFlightAssignment() {
      this.showNewFlightAssignmentDialog = false;

      this.assignmentWarnings = this.getFlightAssignmentWarnings(
        this.employee,
        this.assignmentData.flight,
        this.selectedRole
      );

      let assignment = {
        type: "Flight",
        flight_id: this.assignmentData.flight._id,
        startTime: this.assignmentData.flight.effectiveTimeOut,
        endTime: this.assignmentData.flight.effectiveTimeIn,
        originIata: this.assignmentData.flight.originIata,
        destinationIata: this.assignmentData.flight.destinationIata,
        employee_id: this.employee._id,
        role: this.selectedRole,
        warnings: [...this.assignmentWarnings],
        remarks: this.remarks,
      };

      if (this.assignmentData.crewmember) {
        assignment.crewmember_id = this.assignmentData.crewmember._id;
      }

      if (this.assignmentWarnings.length) {
        this.assignment = assignment;
        this.showAssigmentWarningsModal = true;
        return;
      }

      this.loading = true;
      let res;

      if (this.assignmentData.crewmember) {
        res = await this.$store.dispatch("coverFlightAssignment", assignment);
      } else {
        res = await this.$store.dispatch("saveFlightAssignment", assignment);
      }

      this.loading = false;

      if (res.status !== 200) {
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.getData();
    },

    handleShowFlightAssignmentInformationDialog(flightAssignment, e) {
      e.stopPropagation();

      this.flightAssignment = {
        ...flightAssignment,
      };

      this.showFlightAssignmentInformationDialog = true;
    },

    async hideFlightAssignmentInformationDialog(e) {
      this.showFlightAssignmentInformationDialog = false;
      this.flightAssignment = null;

      if (e.action === "refresh") {
        this.getData();
      }
    },

    //------------------------------------------Assignments (Shared)
    async handleDeleteAssignments() {
      let crewmembers = [];
      let stationAssignment_ids = [];
      let otherAssignment_ids = [];

      this.selectedAssignments.forEach((item) => {
        if (item.type === "Flight") {
          crewmembers.push({
            flight_id: item.flight_id,
            crewmember_id: item._id,
          });
        } else if (item.type === "Station") {
          stationAssignment_ids.push(item._id);
        } else {
          otherAssignment_ids.push(item._id);
        }
      });

      this.loading = true;

      const res = await this.$store.dispatch("deleteAssignments", {
        crewmembers,
        stationAssignment_ids,
        otherAssignment_ids,
      });

      this.loading = false;

      if (res.status !== 200) {
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.getData();
      this.boardStyle = "Board";
    },

    //--------------------------------------Trip Assignments
    handleStartNewTripAssignment() {
      this.selectedRole = this.getSelectedRole();

      this.showNewTripAssignmentDialog = true;
    },

    async handleSaveTripAssignment() {
      this.showNewTripAssignmentDialog = false;

      let assignmentsMap = new Map();
      let flight;
      let warnings = [];
      this.assignmentWarnings = [];

      for (let i = 0; i < this.assignmentData.trip.flights.length; i++) {
        flight = this.assignmentData.trip.flights[i];

        let role = this.tripRoles.get(flight._id);

        if (!role) {
          role = this.selectedRole.slice();
        }

        warnings = this.getFlightAssignmentWarnings(
          this.employee,
          flight,
          role
        );

        // Add only the first route discontiunity if applicable
        if (i > 0) {
          warnings = warnings.filter((element) => {
            return element.type !== "Route Discontinuity";
          });
        }

        if (warnings.length) {
          for (let index = 0; index < warnings.length; index++) {
            const item = warnings[index];

            const x = this.assignmentWarnings.findIndex((warning) => {
              return warning.message === item.message;
            });

            if (x < 0) {
              this.assignmentWarnings.push(item);
            }
          }
        }

        assignmentsMap.set(flight._id, {
          flight,
          warnings,
          role,
        });
      }

      this.assignment = {
        type: "Trip",
        assignments: Array.from(assignmentsMap.values()),
      };

      if (this.assignmentWarnings.length) {
        this.showNewTripAssignmentDialog = false;
        this.showAssigmentWarningsModal = true;
        return;
      }

      this.loading = true;

      const res = await this.$store.dispatch("saveTripAssignment", {
        employee_id: this.employee._id,
        remarks: this.remarks,
        assignment: this.assignment,
      });

      this.assignment = null;
      this.loading = false;

      if (res.status !== 200) {
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.getData();
    },

    //-----------------------Ground Assignments
    handleStartNewGroundAssignment(item, e) {
      this.employee = {
        ...item.employee,
        assignments: [...item.assignments],
      };

      // Assuming you have a ref named "scrollContainer" for the scrollable container
      const scrollContainer = this.$refs.scrollContainer;

      // Calculate the x position relative to the scrollable container
      const x =
        e.clientX -
        scrollContainer.getBoundingClientRect().left +
        scrollContainer.scrollLeft;

      const minutes = x * this.scale;

      const startDateTime = new Date(
        new Date(this.dateFilter.start).getTime() + minutes * 60 * 1000
      ).toISOString();

      const endDateTime = new Date(
        new Date(this.dateFilter.start).getTime() +
          minutes * 60 * 1000 +
          8 * 60 * 60 * 1000 //Add 8 hours to start time
      ).toISOString();

      this.groundAssignmentStartDate = startDateTime.substring(0, 10);
      this.groundAssignmentStartTime = startDateTime.substring(11, 16);

      this.groundAssignmentEndDate = endDateTime.substring(0, 10);
      this.groundAssignmentEndTime = endDateTime.substring(11, 16);

      this.selectedGroundAssignmentType = this.groundAssignmentTypes[0];

      this.groundAssignment = null;
      this.grounAssignmentDestination = null;
      this.grounAssignmentRemarks = null;

      this.showNewGroundAssignmentDialog = true;
    },

    async handleSaveGroundAssignment() {
      let d1;
      let d2;

      this.groundAssignmentOrigin = null;
      this.groundAssignmentDestination = null;

      switch (this.selectedGroundAssignmentType) {
        case "Special Assignment":
        case "Layover":
        case "Training - Ground School":
        case "Training - SIM":
        case "Do Not Use":
          //Check Origin IATA
          this.groundAssignmentOrigin = this.airports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!this.groundAssignmentOrigin) {
            this.snackbarColor = "red";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          this.groundAssignmentDestination = { ...this.groundAssignmentOrigin };

          d1 = `${this.groundAssignmentStartDate}T${this.groundAssignmentStartTime}:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T${this.groundAssignmentEndTime}:59.999+00:00`;

          break;
        case "Stand By":
          //Check Origin IATA
          this.groundAssignmentOrigin = this.airports.find((airport) => {
            return airport.iata === this.employee.gatewayCode;
          });

          if (!this.groundAssignmentOrigin) {
            this.snackbarColor = "red";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          this.groundAssignmentDestination = { ...this.groundAssignmentOrigin };

          d1 = `${this.groundAssignmentStartDate}T00:00:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T23:59:59.999+00:00`;

          break;
        case "Quarantine":
        case "Quarantine - Day OFF":
          //Check Origin IATA
          this.groundAssignmentOrigin = this.airports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!this.groundAssignmentOrigin) {
            this.snackbarColor = "red";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          this.groundAssignmentDestination = { ...this.groundAssignmentOrigin };

          d1 = `${this.groundAssignmentStartDate}T00:00:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T23:59:59.999+00:00`;
          break;
        case "Travel":
          //Check Origin IATA
          this.groundAssignmentOrigin = this.airports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!this.groundAssignmentOrigin) {
            this.snackbarColor = "red";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Check Destination IATA
          this.groundAssignmentDestination = this.airports.find((airport) => {
            return (
              airport.iata ===
              this.groundAssignmentDestinationIata.toUpperCase()
            );
          });

          if (!this.groundAssignmentDestination) {
            this.snackbarColor = "red";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentDestinationIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          d1 = `${this.groundAssignmentStartDate}T${this.groundAssignmentStartTime}:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T${this.groundAssignmentEndTime}:59.999+00:00`;
          break;
        case "Sick":
          //Check Origin IATA
          this.groundAssignmentOrigin = this.airports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!this.groundAssignmentOrigin) {
            this.snackbarColor = "red";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          this.groundAssignmentDestination = { ...this.groundAssignmentOrigin };

          d1 = `${this.groundAssignmentStartDate}T${this.groundAssignmentStartTime}:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T${this.groundAssignmentEndTime}:59.999+00:00`;
          break;
        default:
          // case "Vacation":
          // case "Holiday":
          // case "LOA - Jury Duty":
          // case "LOA - Long Term Disability":
          // case "LOA - Short Term Disability":
          // case "LOA - Workers' Compensation":
          // case "Floating Holiday":
          // case "Leave - Bereavement":
          // case "Leave - FMLA":
          // case "Leave - Medical":
          // case "Leave - Military":
          // case "Leave - Non-FMLA":
          // case "Leave - Parental":
          // case "Leave - Personal":

          //Check Origin IATA
          this.groundAssignmentOrigin = this.airports.find((airport) => {
            return airport.iata === this.employee.gatewayCode;
          });

          if (!this.groundAssignmentOrigin) {
            this.snackbarColor = "red";
            this.snackbarText = `We couln't find an airport with IATA code ${this.employee.gatewayCode.toUpperCase()}. Please check employee's Gateway.`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          this.groundAssignmentDestination = { ...this.groundAssignmentOrigin };
          d1 = `${this.groundAssignmentStartDate}T00:00:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T23:59:59.999+00:00`;

          break;
      }

      this.groundAssignmentStartDateTime = new Date(d1);
      this.groundAssignmentEndDateTime = new Date(d2);

      this.groundAssignmentDuration = Math.ceil(
        (this.groundAssignmentEndDateTime.getTime() -
          this.groundAssignmentStartDateTime.getTime()) /
          60 /
          1000
      );

      if (
        this.groundAssignmentDuration <= this.groundAssignmentMinimumDuration
      ) {
        this.snackbarColor = "red";
        this.snackbarText = `The minimum duration for a ground assignment is ${this.groundAssignmentMinimumDuration} minutes`;
        this.snackbarVisible = true;
        this.loading = false;
        this.readOnly = true;
        return;
      }

      if (this.selectedGroundAssignmentType === "Travel") {
        if (this.groundAssignmentDuration > 1 * 24 * 60) {
          this.snackbarColor = "red";
          this.snackbarText = `The maximum duration for a travel assignment is 1 day`;
          this.snackbarVisible = true;
          this.loading = false;
          this.readOnly = true;
          return;
        }
      } else {
        if (this.groundAssignmentDuration > 31 * 24 * 60) {
          this.snackbarColor = "red";
          this.snackbarText = `The maximum duration for a ground assignment is 31 days`;
          this.snackbarVisible = true;
          this.loading = false;
          this.readOnly = true;
          return;
        }
      }

      this.showNewGroundAssignmentDialog = false;

      let assignment = {
        type: this.selectedGroundAssignmentType,
        startTime: this.groundAssignmentStartDateTime,
        endTime: this.groundAssignmentEndDateTime,
        duration: this.groundAssignmentDuration,
        originIata: this.groundAssignmentOrigin.iata,
        destinationIata: this.groundAssignmentDestination.iata,
        originCountry: this.groundAssignmentOrigin.country,
        destinationCountry: this.groundAssignmentDestination.country,
        confirmed: this.groundAssignmentConfirmed,
        employee_id: this.employee._id,
        remarks: this.remarks,
      };

      this.assignmentWarnings = this.getGroundAssignmentWarnings(
        this.employee,
        assignment
      );

      assignment.warnings = [...this.assignmentWarnings];

      if (this.assignmentWarnings.length) {
        this.assignment = assignment;
        this.showAssigmentWarningsModal = true;
        return;
      }

      this.loading = true;

      const res = await this.$store.dispatch(
        "saveGroundAssignment",
        assignment
      );

      this.loading = false;

      if (res.status !== 200) {
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        this.$store.commit("updateAssignmentBoardPageNumber", 0);
        return;
      }

      this.remarks = null;
      this.getData();
    },

    async handleShowGroundAssignmentInformationDialog(
      item,
      groundAssignment,
      e
    ) {
      e.stopPropagation();

      this.employee = {
        ...item.employee,
        assignments: [...item.assignments],
      };
      this.groundAssignment = {
        ...groundAssignment,
      };

      this.showGroundAssignmentInformationDialog = true;
    },

    async handleUpdateGroundAssignment(e) {
      this.showGroundAssignmentInformationDialog = false;

      const assignment = e.assignment;

      const warnings = this.getGroundAssignmentWarnings(
        this.employee,
        assignment
      );

      assignment.warnings = warnings;

      if (assignment.warnings.length) {
        this.assignment = assignment;
        this.showAssigmentWarningsModal = true;
        return;
      }

      this.loading = true;

      const res = await this.$store.dispatch(
        "updateGroundAssignment",
        assignment
      );

      this.assignment = null;
      this.employee = null;
      this.loading = false;

      if (res.status !== 200) {
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.getData();
    },

    handleCloseGroundAssignmentInformationDialog(refreshData) {
      this.showGroundAssignmentInformationDialog = false;
      this.groundAssignment = null;
      if (refreshData) {
        this.getData();
      }
    },

    //-----------------------Station Assignments
    async handleShowStationAssignmentInformationDialog(stationAssignment, e) {
      e.stopPropagation();
      try {
        let flights;
        this.loading = true;

        const token = await aad.getIdTokenSilent();
        const res = await api.post(
          "/flights/station-assignment",
          {
            flightIds: stationAssignment.flightIds,
          },
          {
            headers: {
              Authorization: "Bearer " + token.idToken,
            },
          }
        );

        this.loading = false;

        flights = res.data.flights.sort(this.compareFlights);

        this.stationAssignment = {
          ...stationAssignment,
          flights,
        };

        this.showStationAssignmentInformationDialog = true;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
        return;
      }
    },

    hideStationAssignmentInformationDialog(refreshData) {
      this.showStationAssignmentInformationDialog = false;
      this.stationAssignment = null;
      if (refreshData) {
        this.getData();
      }
    },

    async handleSaveStationAssignment() {
      const assignmentStart = new Date(
        `${this.startDate}T${this.startTime}:00.000+00:00`
      ).getTime();

      const assignmentEnd = new Date(
        `${this.endDate}T${this.endTime}:00.000+00:00`
      ).getTime();

      const startAdjustment = Math.abs(
        new Date(this.assignmentData.stationAssignment.startTime).getTime() -
          assignmentStart
      );
      const endAdjustment = Math.abs(
        new Date(this.assignmentData.stationAssignment.endTime).getTime() -
          assignmentEnd
      );

      if (startAdjustment > 6 * 60 * 60 * 1000) {
        //More than 6 hours
        this.snackbarColor = "red";
        this.snackbarText = `The maximum adjustment to the assignment Start Time is +/- 6 hours from ${this.formatDateTime(
          this.assignmentData.stationAssignment.startTime
        )}`;
        this.snackbarVisible = true;
        return;
      }

      if (endAdjustment > 6 * 60 * 60 * 1000) {
        //More than 6 hours
        this.snackbarColor = "red";
        this.snackbarText = `The maximum adjustment to the assignment End Time is +/- 6 hours from ${this.formatDateTime(
          this.assignmentData.stationAssignment.endTime
        )}`;
        this.snackbarVisible = true;
        return;
      }

      const duration =
        (new Date(assignmentEnd) - new Date(assignmentStart)) / (60 * 1000); // In Minutes

      if (duration < 30) {
        //Less thna 30 minutes
        this.snackbarColor = "red";
        this.snackbarText = `The minimum duration for a station assignment is 30 minutes`;
        this.snackbarVisible = true;
        return;
      }

      if (duration > 2 * 24 * 60) {
        // More than 48 hours
        this.snackbarColor = "red";
        this.snackbarText = `The maximum duration for a station assignment is 48 hours`;
        this.snackbarVisible = true;
        return;
      }

      this.showNewStationAssignmentDialog = false;

      let flightIds = [];
      this.assignmentData.stationAssignment.flights.forEach((flight) => {
        flightIds.push(flight.flightId);
      });

      let assignment = {
        type: "Station Assignment",
        employee_id: this.employee._id,
        assignedToCompany: null,
        startTime: new Date(assignmentStart),
        endTime: new Date(assignmentEnd),
        originIata: this.assignmentData.stationAssignment.originIata,
        destinationIata: this.assignmentData.stationAssignment.originIata,
        duration,
        role: this.selectedRole,
        flightIds,
        remarks: this.remarks,
      };

      this.assignmentWarnings = this.getStationAssignmentWarnings(
        this.employee,
        this.assignmentData.stationAssignment.flights,
        assignment
      );

      assignment.warnings = [...this.assignmentWarnings];

      if (assignment.warnings.length) {
        this.assignment = assignment;
        this.showStationAssignmentInformationDialog = false;
        this.showAssigmentWarningsModal = true;
        return;
      }

      this.loading = true;

      const res = await this.$store.dispatch(
        "saveStationAssignment",
        assignment
      );

      this.loading = false;

      if (res.status !== 200) {
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.getData();
    },

    //------------------------Formatters and Stylers

    formatDate(miliseconds) {
      return new Date(miliseconds).toISOString().substring(0, 10);
    },

    formatDateShort(miliseconds) {
      const date = new Date(miliseconds);

      let options = {
        timeZone: "UTC",
        month: "short",
        day: "numeric",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    formatTime(miliseconds) {
      return new Date(miliseconds).toISOString().substring(11, 16);
    },

    formatDateTime(dateTimeString) {
      const date = new Date(dateTimeString);

      let options = {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h23",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    formatAssignment(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const timeIn = this.formatDateTime(assignment.endTime);

      switch (assignment.type) {
        case "Flight":
          return `${assignment.role} . ${assignment.flightNumber} . ${assignment.aircraftRegistration} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z - ${timeIn} Z`;

        case "Travel":
          return `${assignment.type} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z - ${timeIn} Z`;
        case "Station":
          return `Station Assignment . ${assignment.originIata} . ${timeOut} Z - ${timeIn} Z`;

        default:
          return `${assignment.type} . ${assignment.originIata} . ${timeOut} Z - ${timeIn} Z`;
      }
    },

    formatHeaderDate(string) {
      const date = new Date(string);

      let options = {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "long",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    getBidDayStyle(bidDay) {
      //Default color "DO" - Day Off
      let backgroundColor = "#E74C3C";

      switch (bidDay.code) {
        case "RES":
          backgroundColor = "#2ECC71";
          break;
        case "VAC":
          backgroundColor = "#A569BD";
          break;
        default:
          break;
      }

      const x =
        (new Date(Date.UTC(bidDay.year, bidDay.month, bidDay.day)).getTime() -
          new Date(this.dateFilter.days[0]).getTime()) /
        1000 /
        60 /
        this.scale;

      const style = {
        top: 0,
        left: `${x}px`,
        height: `${this.rowHeight}px`,
        width: `${this.dayWidth}px`,
        backgroundColor,
      };

      return style;
    },
  },
};
</script>

<style scoped>
.flight-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  min-width: 250px;
  padding: 5px;
  border-radius: 3px;
  background-color: rgba(128, 128, 128, 0.9);
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  z-index: 700;
}

.right-panel-row {
  position: absolute;
  top: 50px;
  left: 0;
  height: 0;
  width: 0;
  border-bottom: 1px solid gray;
  background-color: white;
  overflow: hidden;
}

.right-panel-row:nth-child(odd) {
  background-color: rgb(236, 225, 225);
}

section {
  padding: 0 1rem;
  min-height: 10rem;
  max-height: 26rem;
  overflow-y: auto;
}

.warning-tile {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px 10px;
  color: black;
  margin-bottom: 3px;
}
</style>
