import { PublicClientApplication } from "@azure/msal-browser";

//DEPLOY

// //PROD;
// const authority =
//   "https://login.microsoftonline.com/2eec8d43-0dd7-4584-b232-bd179dbd1610";
// const clientId = "45286a0a-86dc-42e7-ac6b-58fc4cffafad";
// const redirectUri = "https://monarch.atlasair.com";
// const postLogoutRedirectUri = "https://www.atlasair.com/"; //DEV LOCAL

//UAT
const authority =
  "https://login.microsoftonline.com/2eec8d43-0dd7-4584-b232-bd179dbd1610";
const clientId = "63e64c5e-852b-4933-9a28-15629492299c";
const redirectUri = "https://monarchuat.atlasair.com";
const postLogoutRedirectUri = "https://www.atlasair.com/"; //DEV LOCAL

// //DEV
// const authority =
//   "https://login.microsoftonline.com/d3554ce0-7205-4e69-8e9c-e3fe37c22c54";
// const clientId = "9e3edebc-d4f3-4796-a092-16d2802f50fc";
// //const redirectUri = "https://dev-raiden.com"; //DEV
// const redirectUri = "http://localhost:5173"; //DEV LOCAL
// const postLogoutRedirectUri = "https://www.atlasair.com/"; //DEV LOCAL

// MSAL Configuration
const msalConfig = {
  auth: {
    clientId,
    authority,
    redirectUri,
  },
};

// Create an MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

// Initialize MSAL instance
const initializeMsal = async () => {
  try {
    await msalInstance.initialize();
  } catch (error) {
    console.log("Error during MSAL initialization:", error.message);
  }
};

// Login Function
const login = async () => {
  try {
    const loginRequest = {
      scopes: ["User.Read"],
    };

    // Attempt to login using a popup
    const authResult = await msalInstance.loginPopup(loginRequest);

    // Set the active account after login
    msalInstance.setActiveAccount(authResult.account);

    return { status: "OK", account: authResult.account };
  } catch (error) {
    return { status: "Failed", error };
  }
};

// Initialize and trigger login
const initMsal = async () => {
  await initializeMsal(); // Ensure MSAL is initialized
};

const getIdTokenSilent = async () => {
  const tokenRequest = {
    scopes: ["User.Read"], // Replace with the appropriate scopes for your app
  };

  try {
    // Attempt to silently acquire the token
    const tokenResult = await msalInstance.acquireTokenSilent(tokenRequest);
    return tokenResult;
  } catch (error) {
    console.error("Silent token acquisition failed:", error);

    // Handle specific errors
    if (error.name === "InteractionRequiredAuthError") {
      console.log("Interaction required. Falling back to popup login...");
      const tokenResult = await msalInstance.acquireTokenPopup(tokenRequest);
      return tokenResult;
    } else {
      console.log("Unexpected error. Logging out...");
      msalInstance.logout();
      throw error; // Re-throw the error after logging out
    }
  }
};

const getAccessTokenSilent = async () => {
  const tokenRequest = {
    scopes: ["User.Read"], // Replace with the appropriate scopes
    account: msalInstance.getActiveAccount(), // Provide the active account context
  };

  try {
    const tokenResult = await msalInstance.acquireTokenSilent(tokenRequest);
    return tokenResult.accessToken;
  } catch (error) {
    console.error("Silent token acquisition failed:", error);

    if (error.name === "InteractionRequiredAuthError") {
      console.log("Fallback to interactive login...");
      const tokenResult = await msalInstance.acquireTokenPopup(tokenRequest);
      return tokenResult.accessToken;
    }

    throw error;
  }
};

const getAccount = () => {
  // Retrieve the active account from MSAL
  const account = msalInstance.getActiveAccount();

  if (!account) {
    console.log("No active account found. User may need to log in.");
    return null;
  }

  return account;
};

const logout = async () => {
  const logoutRequest = {
    postLogoutRedirectUri,
    account: msalInstance.getActiveAccount(), // Ensure the logout is tied to the active account
  };

  try {
    const account = logoutRequest.account;

    if (account) {
      await msalInstance.logoutRedirect(logoutRequest);
      return { status: "OK", account };
    } else {
      return { status: "Failed", account: null };
    }
  } catch (error) {
    console.error("Error during logout:", error);
  }
};

export default {
  initMsal,
  login,
  getIdTokenSilent,
  getAccessTokenSilent,
  getAccount,
  logout,
};
